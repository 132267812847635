import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { IoIosClose } from "react-icons/io";
import Box from "@mui/material/Box";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";
import { ErrorToaster } from "../Toster";
import { UpdatepasswordApi } from "../API/AuthAPI";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid rgba(203, 188, 160, 0.8)",
  boxShadow: 24,
  p: 4,
};

const Changepasswordmodel = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const [oldpassword, setOldpassword] = useState("");
  const [showoldpassowrd, setShowoldpassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showpassword, setShowpassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showconfirmpassword, setShowconfirmpassword] = useState(false);
  let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  let token = localStorage.getItem("Henneberry");

  const handlesubmit = (e) => {
    e.preventDefault();
    if (passReg.test(oldpassword) === false) {
      ErrorToaster(
        "Password must contain 8 characters, 1 lowercase,1 uppercase and 1 digit and 1 special character"
      );
    } else if (passReg.test(password) === false) {
      ErrorToaster(
        "Password must contain 8 characters, 1 lowercase,1 uppercase and 1 digit and 1 special character"
      );
    } else if (confirmPassword !== password) {
      ErrorToaster("Confirm Password is not Equel Password");
    } else {
      let fromdata = {
        old_password: oldpassword,
        new_password: password,
        confirm_password: confirmPassword,
      };
      UpdatepasswordApi(token, fromdata, setShow);
    }
  };
  return (
    <div>
      <Modal open={show} onClose={handleClose}>
        <Box sx={style} className="Otpmodel">
          <>
            <div className="d-flex justify-content-between">
              <h5>Change Passoword</h5>
              <IoIosClose style={{ fontSize: "35px" }} onClick={handleClose} />
            </div>
            <form>
              <div className="mb-3">
                <label className="form-label"> Old Password</label>
                <div className="input-box">
                  <div className="icons-confirm">
                    {" "}
                    <MdLockOutline />
                  </div>
                  <div className="input-login">
                    <input
                      type={showoldpassowrd ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={oldpassword}
                      onChange={(e) => setOldpassword(e.target.value)}
                    />
                    <div
                      className=""
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowoldpassword(!showoldpassowrd)}
                    >
                      {showoldpassowrd ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label"> New Password</label>
                <div className="input-box">
                  <div className="icons-confirm">
                    {" "}
                    <MdLockOutline />
                  </div>
                  <div className="input-login">
                    <input
                      type={showpassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className=""
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowpassword(!showpassword)}
                    >
                      {showpassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label className="form-label"> Confirm Password</label>
                <div className="input-box">
                  <div className="icons-confirm">
                    {" "}
                    <MdLockOutline />
                  </div>
                  <div className="input-signup">
                    <input
                      type={showconfirmpassword ? "text" : "password"}
                      className="form-control"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div
                      className=""
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setShowconfirmpassword(!showconfirmpassword)
                      }
                    >
                      {showconfirmpassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 d-flex">
                <button
                  type="submit"
                  className="btn btn-success w-100"
                  style={{
                    background: "rgba(203, 188, 160, 0.8)",
                    border: "rgba(203, 188, 160, 0.8)",
                    color: "black",
                  }}
                  onClick={handlesubmit}
                >
                  Update Password
                </button>
              </div>
            </form>
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default Changepasswordmodel;
