import React, { useEffect, useState } from "react";
import Footer from "../Components/HomeSections/Footer";
import NavBar from "../Components/HomeSections/NavBar";
import FilterHeader from "../Components/PropertyFilters/FilterHeader";
import FilterSearch from "../Components/PropertyFilters/FilterSearch";
import "../Style/PropertyHeader/filterSearch.css";
import { SearchApi } from "../API/CommanApi";
import { useLocation } from "react-router-dom";
import Filterproparty from "../Components/PropertyFilters/Filterproparty";
import {
  filterPropartydataAPI,
  filterPropartyTypeAPI,
  filterPropartyCatgroyAPI,
} from "../API/FilterpropertyAPI";
import Typesfilter from "../Components/PropertyFilters/Typesfilter";
import Pagnitation from "./Pagnitation";
import { useParams } from "react-router-dom";

function PropertyFilters() {
  const { propertytype } = useParams();
  const propertyTypekey = propertytype?.split("_")[1];
  const location = useLocation();
  const propertyTypename = location?.state?.name;
  const [currentPage, setCurrentPage] = useState("1");

  const [loding, setLoding] = useState(false);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchfilter, setSearchfilter] = useState();
  const [propertyData, setPropertyData] = useState([]);
  const [propertyLength, setPropertyLength] = useState(1);
  const [selectbadroom, setSelectbadroom] = useState("5");
  const [selectbathroom, setSelectbathroom] = useState("1");
  const [selectparking, setSelectparking] = useState("3");
  const [selectNew, setSelectNew] = useState("1");
  const [featurekey, setFeaturekey] = useState([]);
  const [totalBedrooms, setTotalBedrooms] = useState([]);
  const [totalBethroom, setTotalBathrooms] = useState([]);
  const [totalParkings, setTotalParking] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minLandSize, setMinLandSize] = useState("");
  const [maxLandSize, setMaxLandSize] = useState("");
  const [outDoorfeatures, setOutDoorfeatures] = useState([]);
  const [propertyTypefilter, setPropertyTypefilter] = useState([]);
  const [unitType, setUnitType] = useState("");
  const [propertyType1, setPropertyType1] = useState([]);
  const [error, setError] = useState("");
  const [unit, setUnit] = useState();

  const handleApply = () => {
    setTotalBedrooms(selectbadroom);
    setTotalBathrooms(selectbathroom);
    setTotalParking(selectparking);
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
    setMinLandSize(minLandSize);
    setMaxLandSize(maxLandSize);
    setOutDoorfeatures(featurekey);
    setPropertyTypefilter(propertyType1);
    setUnitType(unit);
    setOpen(false);
    if (propertyTypename === "HomePageListing") {
      Propertyfilterdata();
    } else if (propertyTypename === "Homepropertylisting") {
      propertyType();
    } else {
      Cattegroylistproperty();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (propertyTypename === "HomePageListing") {
      Propertyfilterdata();
    } else if (propertyTypename === "Homepropertylisting") {
      propertyType();
    } else {
      Cattegroylistproperty();
    }
  }, [
    currentPage,
    propertyTypekey,
    propertyTypefilter,
    minPrice,
    maxPrice,
    totalBedrooms,
    totalBethroom,
    totalParkings,
    unitType,
    outDoorfeatures,
    minLandSize,
    maxLandSize,
    error,
  ]);

  const Propertyfilterdata = () => {
    setLoding(true);
    let fromdata = {
      saleOrLease: propertyTypekey,
      page: currentPage,
      newEst: "",
      propertyType: propertyTypefilter,
      minPrice: minPrice,
      maxPrice: maxPrice,
      totalBedrooms: totalBedrooms,
      totalBathrooms: totalBethroom,
      totalParkings: totalParkings,
      minLandSize: minLandSize,
      maxLandSize: maxLandSize,
      landSizeUnit: unitType,
      outdoorFeatures: outDoorfeatures,
    };
    filterPropartydataAPI(fromdata, setLoding, setError, (callback) => {
      setOpen(false);
      setPropertyLength(callback?.total_records);
      setPropertyData(callback?.data?.propertyList);
    });
  };

  const propertyType = () => {
    setLoding(true);

    let fromdata = {
      saleOrLease: propertyTypekey,
      propertyType: propertyTypekey,
      page: currentPage,
      newEst: "",
      propertyType: propertyTypefilter,
      minPrice: minPrice,
      maxPrice: maxPrice,
      totalBedrooms: totalBedrooms,
      totalBathrooms: totalBethroom,
      totalParkings: totalParkings,
      minLandSize: minLandSize,
      maxLandSize: maxLandSize,
      landSizeUnit: unitType,
      outdoorFeatures: outDoorfeatures,
    };

    filterPropartyTypeAPI(fromdata, setLoding, setError, (callback) => {
      setPropertyLength(callback?.total_records);
      setPropertyData(callback?.data?.propertyList);
    });
  };

  const Cattegroylistproperty = () => {
    setLoding(true);
    let fromdata = {
      saleOrLease: propertyTypekey,
      category_id: propertyTypekey,
      page: currentPage,
      newEst: "",
      propertyType: propertyTypefilter,
      minPrice: minPrice,
      maxPrice: maxPrice,
      totalBedrooms: totalBedrooms,
      totalBathrooms: totalBethroom,
      totalParkings: totalParkings,
      minLandSize: minLandSize,
      maxLandSize: maxLandSize,
      landSizeUnit: unitType,
      outdoorFeatures: outDoorfeatures,
    };
    filterPropartyCatgroyAPI(fromdata, setLoding, setError, (callback) => {
      setPropertyLength(callback?.total_records);
      setPropertyData(callback?.data?.propertyList);
    });
  };

  const handleSearch = () => {
    let fromdata = {
      page: 1,
      search_keyword: search,
      filter_column_name: searchfilter?.column,
      filter_value: searchfilter?.key,
    };
    SearchApi(fromdata, setLoding, setError, (callback) => {
      setPropertyData(callback?.data?.propertyList);
    });
  };

  return (
    <>
      <div className="filterbanner_bg">
        <NavBar />
        <FilterHeader />
        <FilterSearch
          open={open}
          setOpen={setOpen}
          handleSearch={handleSearch}
          search={search}
          setSearch={setSearch}
          setSearchfilter={setSearchfilter}
        />
      </div>
      <div>
        <Typesfilter
          propertyTypename={propertyTypename}
          setSelectbadroom={setSelectbadroom}
          setSelectbathroom={setSelectbathroom}
          setSelectparking={setSelectparking}
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          setMinLandSize={setMinLandSize}
          setMaxLandSize={setMaxLandSize}
          featurekey={featurekey}
          setFeaturekey={setFeaturekey}
          setPropertyType1={setPropertyType1}
          setUnit={setUnit}
          handleApply={handleApply}
          selectbadroom={selectbadroom}
          selectbathroom={selectbathroom}
          selectparking={selectparking}
          propertyType1={propertyType1}
          selectNew={selectNew}
          setSelectNew={setSelectNew}
          open={open}
          setOpen={setOpen}
        />
      </div>

      <Filterproparty
        propertyData={propertyData}
        loding={loding}
        error={error}
      />
      <div className="container">
        <Pagnitation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalLength={propertyLength}
        />
      </div>
      <Footer />
    </>
  );
}

export default PropertyFilters;
