import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toster";

export const loginApi = (
  formdata,
  navigation,
  setPassword,
  setEmail,
  setUserAuthenticationRedux
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      if (response?.data?.status === "success") {
        SuccessToaster(response?.data?.message);
        localStorage.setItem("Henneberry", response?.data?.data?.token);
        setUserAuthenticationRedux(response?.data?.data?.token);
        navigation("/");
        setPassword("");
        setEmail("");
      } else {
        ErrorToaster(response?.data?.message);
      }
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};
export const SignAPI = (
  formdata,
  navigation,
  setEmail,
  setPassword,
  setName,
  setConfirmPassword,
  setPhone
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);

      navigation("/login");
      setPassword("");
      setEmail("");
      setConfirmPassword("");
      setPhone("");
      setPassword("");
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};
export const ForgetAPI = (formdata, setShow, setEmail) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}forgot/password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);

      setShow(true);
      setEmail("");
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const OtpverifyApi = (formdata, navigation, setShow) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}otp/verification`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      navigation("/reset", {
        state: { reset_token: response?.data?.data?.token },
      });

      setShow(false);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const ResetpasswordAPI = (formdata, navigation) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}reset/password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);

      navigation("/login");
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const UpdatepasswordApi = (token, formdata, setShow) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update/password`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);

      setShow(false);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const logOutapi = (token, navigation) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}logout`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);

      localStorage.removeItem("Henneberry");
      navigation("/");
      window.location.reload();
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};
