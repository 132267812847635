import React from "react";
import "../../Style/HomepageListing.css";
import listingimage from "../../Images/HomePageImages/homelisting_product_img_one.png";
import homelistingheadingimg from "../../Images/HomePageImages/DH2 2.png";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../../Style/Navbar.css";
const HomePageListing = ({ listingData }) => {
  const navigation = useNavigate();
  const handleclick = (key, slug) => {
    navigation(`/property-filters/${slug}_${key}`, {
      state: { propertyTypekey: key, name: "HomePageListing" },
    });
  };

  return (
    <>
      <div className="homepageListing">
        <div className="container-xxl p-0">
          <div className="homelistingheadingimg">
            <img src={homelistingheadingimg} alt="img" />
          </div>
          <div className="row">
            {listingData.map((iteam, i) => {
              const { name, key, slug } = iteam;

              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-6"
                  key={i}
                  onClick={() => handleclick(key, slug)}
                >
                  <div className="producta_card">
                    <img src={listingimage} alt="img" />
                    <div className="listing_text">
                      <h5>{name} </h5>
                      <span>
                        View All <FaAngleRight />{" "}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageListing;
