import React, { useState } from "react";
import "../Style/Reset.css";
import Footer from "../Components/HomeSections/Footer";
import FilterHeader from "../Components/PropertyFilters/FilterHeader";
import { ErrorToaster } from "../Toster";
import { MdLockOutline } from "react-icons/md";
import { ResetpasswordAPI } from "../API/AuthAPI";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const Resetpassword = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const Reset_password = location.state.reset_token;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showconfirmpassword, setShowconfirmpassword] = useState(false);

  const [showpassword, setShowpassword] = useState(false);
  let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passReg.test(password) === false) {
      ErrorToaster(
        "Password must contain 8 characters, 1 lowercase,1 uppercase and 1 digit and 1 special character"
      );
    } else if (confirmPassword !== password) {
      ErrorToaster("Confirm Password not equel password");
    } else {
      let fromdata = {
        token: Reset_password,
        newPassword: password,
        confirmPassword: confirmPassword,
      };
      ResetpasswordAPI(fromdata, navigation);
    }
  };

  return (
    <>
      <div className="filterbanner_bg">
        <FilterHeader />
      </div>
      <div className="container">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: 600 }}
        >
          <div className="login-box">
            <h5>Welcome to DI HENNEBERRY </h5>
            <p>We make it easy for everyone to invesst in real estate</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label"> New Password</label>
                <div className="input-box">
                  <div className="icons-reset">
                    {" "}
                    <MdLockOutline />
                  </div>
                  <div className="input-reset">
                    <input
                      type={showpassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className=""
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowpassword(!showpassword)}
                    >
                      {showpassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label"> Confirm Password</label>
                <div className="input-box">
                  <div className="icons-reset">
                    {" "}
                    <MdLockOutline />
                  </div>
                  <div className="input-reset">
                    <input
                      type={showconfirmpassword ? "text" : "password"}
                      className="form-control"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div
                      className=""
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setShowconfirmpassword(!showconfirmpassword)
                      }
                    >
                      {showconfirmpassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  width: "100%",
                  marginTop: 10,
                  backgroundColor: "rgba(203, 188, 160, 0.8)",
                  color: "black",
                  border: "none",
                }}
              >
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Resetpassword;
