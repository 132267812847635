import React from "react";
import "../../Style/HomeSecondBanner.css";
import Button from "react-bootstrap/Button";

const SecondBanner = () => {
  return (
    <>
      <div className="second_banner d-flex justify-content-center align-items-center flex-column">
      <div className="container-xxl p-0">
        <h3 className="d-flex justify-content-center align-items-center">DI HENNEBERRY</h3>
        <p className=" text-center">
          Whether buying or selling, Di Henneberry delivers service beyond
          comparison. Di Henneberry works closely with each of her clients to
          find their ultimate property in the most premier locations, and
          secures the best deal. When listing real estate, Di Henneberry
          maximizes each property’s market value with her unmatched marketing
          strategy.
        </p>
        <div className="d-flex justify-content-center align-items-center flex-wrap gap-4">
          <Button className="workbtn">Work With Di Henneberry</Button>
          <Button className="searchbtn">Home Search</Button>
        </div>
        </div>
      </div>
    </>
  );
};

export default SecondBanner;
