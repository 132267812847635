import React, { useEffect, useState } from "react";
import NavBar from "../Components/HomeSections/NavBar";
import Footer from "../Components/HomeSections/Footer";
import FilterHeader from "../Components/PropertyFilters/FilterHeader";
import { useLocation } from "react-router-dom";
import { SingleblogApi } from "../API/BlogApi";
import "../Style/Singleblog.css";
import Loader from "../Loader";
import { useParams } from "react-router-dom";

const BlogDetailssingle = () => {
  const { slug } = useParams();
  const blogid = slug?.split("_")[1];
  const [data, setData] = useState({});
  const location = useLocation();
  const [loding, setLoding] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    blogdata();
  }, [blogid]);

  const blogdata = () => {
    setLoding(true);
    let fromdata = {
      id: blogid,
    };
    SingleblogApi(fromdata, setLoding, (callback) => {
      setData(callback?.data?.blog_details);
    });
  };

  return (
    <>
      <div className="filterbanner_bg">
        <NavBar />
        <FilterHeader />
      </div>

      <div className="container-xxl p-0">
        {loding ? (
          <Loader />
        ) : (
          <div
            className="container px-sm-0 px-3"
            style={{ minHeight: "300px" }}
          >
            <div className="row mt-5">
              <div className="imgesection">
                <img src={loding ? <Loader /> : data?.image} />
              </div>
              <div className="singleblogcontent">
                <h6 className="single-title">{data?.title}</h6>
                <div dangerouslySetInnerHTML={{ __html: data?.description }} />
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default BlogDetailssingle;
