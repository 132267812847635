import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster } from "../Toster";

export const uploadTenancyFormApi = (data, setFormData) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}tenancy/lease/application`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response.data.message);
      setFormData({
        propertyAddress: "",
        tenancyLength: "",
        rentAmount: "",
        commencementDate: "",
        applicant1: {
          title: "",
          surname: "",
          givenNames: "",
          dob: "",
          currentAddress: "",
          homePhone: "",
          workPhone: "",
          mobilePhone: "",
          email: "",
          driversLicenceNo: "",
          stateOfIssue: "",
          passportNo: "",
          country: "",
          pensionNo: "",
          type: "",
        },
        applicant2: {
          title: "",
          surname: "",
          givenNames: "",
          dob: "",
          currentAddress: "",
          homePhone: "",
          workPhone: "",
          mobilePhone: "",
          email: "",
          driversLicenceNo: "",
          stateOfIssue: "",
          passportNo: "",
          country: "",
          pensionNo: "",
          type: "",
        },
        occupants: [
          { name: "", age: "" },
          { name: "", age: "" },
        ],
        petDetails: [
          {
            breed: "",
            petAge: "",
            councilRegistrationNo: "",
          },
          {
            breed: "",
            petAge: "",
            councilRegistrationNo: "",
          },
        ],
        utilityConsent: false,
        applicant1_sign: "",
        applicant2_sign: "",
        applicant1SigningDate: "",
        applicant2SigningDate: "",
        applicant2SignInfo: {
          name: "",
          phone: "",
        },
      });
    })
    .catch((error) => {});
};

export const uploadSignApi = (data, callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}tenancy/lease/application/sign`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {});
};

export const FAQAPI = (callback) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}get/faqs`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {});
};
export const categoryListhomeAPI = (callback) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}category/list`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {});
};

export const SearchApi = (fromdata, setLoding, setError, callback) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}search/property`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      setLoding(false);
      callback(response?.data);
    })
    .catch((error) => {
      setLoding(false);
      if (error?.response?.data?.status === "failed") {
        setError(error?.response?.data?.message);

        callback([]);
      }
    });
};

export const TestiMonialsAPI = (callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}get/testimonial/list`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {});
};
