import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../../Style/Testimonial.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { TestiMonialsAPI } from "../../API/CommanApi";
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  // autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
  ],
};
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return <FaChevronRight className="slider_Arrowr_right" onClick={onClick} />;
};
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return <FaChevronLeft className="slider_Arrowr_left" onClick={onClick} />;
};
const Testimonial = () => {
  const [testimonial, setTestimonial] = useState([]);
  let [expenend, setExpenend] = useState(true);

  useEffect(() => {
    testmonialdata();
  }, []);
  const testmonialdata = () => {
    TestiMonialsAPI((callback) => {
      setTestimonial(callback?.data?.testimonial_list);
    });
  };
  return (
    <>
      <div className="RatingSection">
        <div className="text-center">
          <h3>Testimonial</h3>
          <p className="pb-3">What our clients say about Di</p>
        </div>
        <div className="container">
          <div className="Testinomial_slider  row ">
            <Slider
              {...settings}
              nextArrow={<CustomNextArrow />}
              prevArrow={<CustomPrevArrow />}
            >
              {testimonial.map((data, i) => {
                const { name, profile, description, image, id } = data;
                return (
                  <div className="col-md-6 p-3" key={i}>
                    <div className="card_sec">
                      <div className="row d-flex justify-content-center align-items-start ">
                        <div className="col-md-12 col-12">
                          <div className="d-flex align-items-center justify-content-between ">
                            <h5>{name}</h5>
                          </div>
                          <p className="text-start slider_content">
                            {description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
