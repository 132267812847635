import React from "react";
import "../Style/Aboutus.css";
import NavBar from "../Components/HomeSections/NavBar";
import Header from "../Components/HomeSections/Header";
import Footer from "../Components/HomeSections/Footer";
const Aboutus = () => {
  return (
    <>
      <div className="banner_bg">
        <NavBar />
        <Header />
        <h1 className="d-flex align-items-center justify-content-center" style={{color:"#f7e0b3"}}>
          About Us
        </h1>
      </div>
      <div className="container">
        <div className="about-contact">
          <h5>MEET DI HENNEBERRY</h5>
          <p>
            Di Henneberry of Henneberry Property Group embodies the art of
            refinement. As a multi-award winning industry identity with an
            enviable, 20 year career at some of Sydney’s most prestigious firms,
            Di Henneberry brings a sparkle to every project that she’s involved
            in. As multi-faceted as a diamond, Di Henneberry offers an elite
            service in so many different ways: This bespoke service will have
            her close to your side whether you’re selling or buying an
            investment or have a property under management Of course, she takes
            a forensic approach to property inspections. It’s the small details,
            sometimes overlooked, which may prove crucial to a future lifestyle.
            Her knowledge of a property’s investment potential has been garnered
            throughout her long career, not only in the eastern suburbs but
            throughout New South Wales. Henneberry Property Group has an
            industry contact book that’s second to none, at the highest level
            and Di Henneberry takes a personal approach to negotiating on your
            behalf.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Aboutus;
