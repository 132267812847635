import React,{useEffect} from "react";
import "../Style/Contactus.css";
import NavBar from "../Components/HomeSections/NavBar";
import Header from "../Components/HomeSections/Header";
import Footer from "../Components/HomeSections/Footer";
import ContactForm from "../Components/HomeSections/ContactForm";

const Contactpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="banner_bg">
        <NavBar />
        <Header />
        <h1 className="d-flex align-items-center justify-content-center " style={{color:"#f7e0b3"}}>
          Contact Us
        </h1>
      </div>
      <div className="container mt-5 mb-5">
        <div className="contactpage">
          <ContactForm />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contactpage;
