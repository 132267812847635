import React, { useState } from "react";
import "../../Style/PropertyHeader/filterSearch.css";

const FilterSearch = ({
  open,
  setOpen,
  handleSearch,
  search,
  setSearch,
  setSearchfilter,
}) => {
  const filterdata = [
    {
      name: "Sale",
      key: "1",
      column: "sale_lease",
    },
    {
      name: "Lease",
      key: "2",
      column: "sale_lease",
    },
    {
      name: "Established",
      key: "1",
      column: "new_establish",
    },
    {
      name: "New",
      key: "2",
      column: "new_establish",
    },
  ];
  const [show1, setShow1] = useState("");

  const handlefilter = (item) => {
    setShow1(item.name);
    setSearchfilter(item);
  };
  return (
    <>
      {/* <div className="filter_search_tabs">
      <div className="container-xxl p-0">
        <div className="search_box">
          <div className="row">
            <div className="col-md-10">
              <div className="search_tabs">
                <div className="navTabs">
                  <ul className="p-0 m-0">
                   {filterdata?.map((iteam,i)=>{

                   return( 
                    <li onClick={()=>handlefilter(iteam)} className={show1===iteam.name?"active":""}>{iteam?.name}</li>)
                   })} 
                    
                  </ul>
                </div>
                <div className="search_input">
                  <input type="text" placeholder="Where are you looking?" name="search" value={search} onChange={(e)=>setSearch(e.target.value)} />
                  <div className="filter_button d-flex align-items-center" onClick={()=>setOpen(!open)}>
                    <FaBarsProgress />
                    <span>Filters</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 d-flex align-items-end">
              <button className="searchBtn gap-2 d-flex align-items-center" onClick={handleSearch}>
                <IoMdSearch /> Search
              </button>
            </div>
          </div>
        </div>

        </div>
      </div> */}
    </>
  );
};

export default FilterSearch;
