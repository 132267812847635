import React, { useState } from "react";
import "../Style/TenancyApplicationForm.css";
import NavBar from "../Components/HomeSections/NavBar";
import Header from "../Components/HomeSections/Header";
import Footer from "../Components/HomeSections/Footer";
import { uploadSignApi, uploadTenancyFormApi } from "../API/CommanApi";

function TenancyApplicationForm() {
  const [formData, setFormData] = useState({
    propertyAddress: "",
    tenancyLength: "",
    rentAmount: "",
    commencementDate: "",
    applicant1: {
      title: "",
      surname: "",
      givenNames: "",
      dob: "",
      currentAddress: "",
      homePhone: "",
      workPhone: "",
      mobilePhone: "",
      email: "",
      driversLicenceNo: "",
      stateOfIssue: "",
      passportNo: "",
      country: "",
      pensionNo: "",
      type: "",
    },
    applicant2: {
      title: "",
      surname: "",
      givenNames: "",
      dob: "",
      currentAddress: "",
      homePhone: "",
      workPhone: "",
      mobilePhone: "",
      email: "",
      driversLicenceNo: "",
      stateOfIssue: "",
      passportNo: "",
      country: "",
      pensionNo: "",
      type: "",
    },
    occupants: [
      { name: "", age: "" },
      { name: "", age: "" },
    ],
    petDetails: [
      {
        breed: "",
        petAge: "",
        councilRegistrationNo: "",
      },
      {
        breed: "",
        petAge: "",
        councilRegistrationNo: "",
      },
    ],
    utilityConsent: false,
    applicant1_sign: "",
    applicant2_sign: "",
    applicant1SigningDate: "",
    applicant2SigningDate: "",
    applicant2SignInfo: {
      name: "",
      phone: "",
    },
  });

  const handleInputChange = (e, section, field, index = 0) => {
    const value = e.target.value;
    if (
      section === "applicant1" ||
      section === "applicant2" ||
      section === "applicant2SignInfo"
    ) {
      setFormData({
        ...formData,
        [section]: {
          ...formData[section],
          [field]: value,
        },
      });
    } else if (section === "occupants") {
      const updatedOccupants = [...formData.occupants];
      updatedOccupants[index][field] = value;
      setFormData({
        ...formData,
        occupants: updatedOccupants,
      });
    } else if (section === "petDetails") {
      const updatedPetDetails = [...formData.petDetails];
      updatedPetDetails[index][field] = value;
      setFormData({
        ...formData,
        petDetails: updatedPetDetails,
      });
    } else if (section === "utilityConsent") {
      setFormData({
        ...formData,
        utilityConsent: e.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  const handleFileInputChange = (e, fieldName) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const data = {
        applicantSign: reader.result,
      };

      uploadSignApi(data, (callback) => {
        setFormData({
          ...formData,
          [fieldName]: callback.data.applicant_sign,
        });
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    uploadTenancyFormApi(formData, setFormData);
  };
  return (
    <>
      <div className="banner_bg">
        <NavBar />
        <Header />
        <h1 className="d-flex align-items-center justify-content-center text-white">
          Tenancy Application Form
        </h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="detailForm">
          <div class="heading">
            <h4>Residential Tenancy Application Form</h4>
            <p>
              For your application to be processed you must answer all questions
            </p>
          </div>
          <div class="rental">
            <h6>
              Rental Property <sup>*</sup>
            </h6>
            <input
              type="text"
              placeholder="Address of Property :"
              value={formData.propertyAddress}
              onChange={(e) => handleInputChange(e, "", "propertyAddress")}
              required
            />
          </div>
          <div class="Tenancy">
            <h6>
              Tenancy Requirements <sup>*</sup>
            </h6>
            <div class="row">
              <div class="col-md-4">
                <input
                  type="number"
                  placeholder="Length of tenancy in months"
                  value={formData.tenancyLength}
                  onChange={(e) => handleInputChange(e, "", "tenancyLength")}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E") {
                      e.preventDefault();
                    }
                  }}
                  required
                />
              </div>
              <div class="col-md-4">
                <input
                  type="number"
                  placeholder="Rent per week in $"
                  value={formData.rentAmount}
                  onChange={(e) => handleInputChange(e, "", "rentAmount")}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E") {
                      e.preventDefault();
                    }
                  }}
                  required
                />
              </div>
              <div class="col-md-4">
                <input
                  type="date"
                  class="date"
                  value={formData.commencementDate}
                  onChange={(e) => handleInputChange(e, "", "commencementDate")}
                  required
                />
              </div>
            </div>
          </div>
          <div class="Applicant">
            <h6>Applicant Details</h6>
            <div class="row">
              <div class="col-md-6">
                <h6>Applicant 1</h6>

                <div class="checkBoxes mt-2">
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant1Title"
                      value="Mr"
                      checked={formData.applicant1.title === "Mr"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "title")
                      }
                    />
                    <span>Mr</span>
                  </div>
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant1Title"
                      value="Dr"
                      checked={formData.applicant1.title === "Dr"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "title")
                      }
                    />
                    <span>Dr</span>
                  </div>
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant1Title"
                      value="Mrs"
                      checked={formData.applicant1.title === "Mrs"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "title")
                      }
                    />
                    <span>Mrs </span>
                  </div>
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant1Title"
                      value="Ms"
                      checked={formData.applicant1.title === "Ms"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "title")
                      }
                    />
                    <span>Ms</span>
                  </div>
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant1Title"
                      value="Miss"
                      checked={formData.applicant1.title === "Miss"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "title")
                      }
                    />
                    <span>Miss</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Surname<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={formData.applicant1.surname}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "surname")
                      }
                      required
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Given names<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={formData.applicant1.givenNames}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "givenNames")
                      }
                      required
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Date of Birth<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      value={formData.applicant1.dob}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "dob")
                      }
                      required
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Current address<sup>*</sup>
                    </label>
                    <input
                      type="type"
                      class="form-control"
                      value={formData.applicant1.currentAddress}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "currentAddress")
                      }
                      required
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Home Phone
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      value={formData.applicant1.homePhone}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "homePhone")
                      }
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "E") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Work Phone
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      value={formData.applicant1.workPhone}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "workPhone")
                      }
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "E") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Mobile Phone<sup>*</sup>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      value={formData.applicant1.mobilePhone}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "mobilePhone")
                      }
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "E") {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Email<sup>*</sup>
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      value={formData.applicant1.email}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "email")
                      }
                      required
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Drivers Licence No
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={formData.applicant1.driversLicenceNo}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "driversLicenceNo")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      State of Issue
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      value={formData.applicant1.stateOfIssue}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "stateOfIssue")
                      }
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Passport No
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      value={formData.applicant1.passportNo}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "passportNo")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Country<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={formData.applicant1.country}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "country")
                      }
                      required
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Pension No
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      value={formData.applicant1.pensionNo}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "pensionNo")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      type
                    </label>
                    <input
                      type="type"
                      class="form-control"
                      value={formData.applicant1.type}
                      onChange={(e) =>
                        handleInputChange(e, "applicant1", "type")
                      }
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <h6>Applicant 2</h6>

                <div class="checkBoxes mt-2">
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant2Title"
                      value="Mr"
                      checked={formData.applicant2.title === "Mr"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "title")
                      }
                    />
                    <span>Mr</span>
                  </div>
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant2Title"
                      value="Dr"
                      checked={formData.applicant2.title === "Dr"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "title")
                      }
                    />
                    <span>Dr</span>
                  </div>
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant2Title"
                      value="Mrs"
                      checked={formData.applicant2.title === "Mrs"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "title")
                      }
                    />
                    <span>Mrs</span>
                  </div>
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant2Title"
                      value="Ms"
                      checked={formData.applicant2.title === "Ms"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "title")
                      }
                    />
                    <span>Ms</span>
                  </div>
                  <div class="box">
                    <input
                      type="radio"
                      name="applicant2Title"
                      value="Miss"
                      checked={formData.applicant2.title === "Miss"}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "title")
                      }
                    />
                    <span>Miss</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Surname
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={formData.applicant2.surname}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "surname")
                      }
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Given names
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={formData.applicant2.givenNames}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "givenNames")
                      }
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      value={formData.applicant2.dob}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "dob")
                      }
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Current address
                    </label>
                    <input
                      type="type"
                      class="form-control"
                      value={formData.applicant2.currentAddress}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "currentAddress")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Home Phone
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      value={formData.applicant2.homePhone}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "homePhone")
                      }
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "E") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Work Phone
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      value={formData.applicant2.workPhone}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "workPhone")
                      }
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "E") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Mobile Phone
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      value={formData.applicant2.mobilePhone}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "mobilePhone")
                      }
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "E") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="your-name" class="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      value={formData.applicant2.email}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "email")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Drivers Licence No
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      value={formData.applicant2.driversLicenceNo}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "driversLicenceNo")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      State of Issue
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      value={formData.applicant2.stateOfIssue}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "stateOfIssue")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Passport No
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      value={formData.applicant2.passportNo}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "passportNo")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Country
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={formData.applicant2.country}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "country")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      Pension No
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      value={formData.applicant2.pensionNo}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "pensionNo")
                      }
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="your-name" class="form-label">
                      type
                    </label>
                    <input
                      type="type"
                      class="form-control"
                      value={formData.applicant2.type}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2", "type")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="Occupancy">
            <h6>Occupancy Details</h6>
            <p>Names and ages of those permanently residing at the property:</p>
            <div class="row mt-3">
              <div class="col-md-6">
                <label for="your-name" class="form-label">
                  Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.occupants[0].name}
                  onChange={(e) => handleInputChange(e, "occupants", "name", 0)}
                />
              </div>
              <div class="col-md-6">
                <label for="your-name" class="form-label">
                  Age
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.occupants[0].age}
                  onChange={(e) => handleInputChange(e, "occupants", "age", 0)}
                />
              </div>
              <div class="col-md-6">
                <label for="your-name" class="form-label">
                  Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.occupants[1].name}
                  onChange={(e) => handleInputChange(e, "occupants", "name", 1)}
                />
              </div>
              <div class="col-md-6">
                <label for="your-name" class="form-label">
                  Age
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.occupants[1].age}
                  onChange={(e) => handleInputChange(e, "occupants", "age", 1)}
                />
              </div>
            </div>
            <p className="mt-2">
              Details of any pets (please include information including Breed,
              Age & Council Registration No):
            </p>
            <div class="row mt-3">
              <div class="col-md-4">
                <label for="your-name" class="form-label">
                  Breed
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.petDetails[0].breed}
                  onChange={(e) =>
                    handleInputChange(e, "petDetails", "breed", 0)
                  }
                />
              </div>
              <div class="col-md-4">
                <label for="your-name" class="form-label">
                  Age
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.petDetails[0].petAge}
                  onChange={(e) =>
                    handleInputChange(e, "petDetails", "petAge", 0)
                  }
                />
              </div>
              <div class="col-md-4">
                <label for="your-name" class="form-label">
                  {" "}
                  Council Registration No
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.petDetails[0].councilRegistrationNo}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "petDetails",
                      "councilRegistrationNo",
                      0
                    )
                  }
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <label for="your-name" class="form-label">
                  Breed
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.petDetails[1].breed}
                  onChange={(e) =>
                    handleInputChange(e, "petDetails", "breed", 1)
                  }
                />
              </div>
              <div class="col-md-4">
                <label for="your-name" class="form-label">
                  Age
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.petDetails[1].petAge}
                  onChange={(e) =>
                    handleInputChange(e, "petDetails", "petAge", 1)
                  }
                />
              </div>
              <div class="col-md-4">
                <label for="your-name" class="form-label">
                  Council Registration No
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={formData.petDetails[1].councilRegistrationNo}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "petDetails",
                      "councilRegistrationNo",
                      1
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div class="utility">
            <h6>
              Utility Connections<sup>*</sup>
            </h6>
            <div class="box">
              <input
                type="checkbox"
                className="me-2"
                onChange={(e) =>
                  handleInputChange(e, "utilityConsent", "utilityConsent")
                }
                required
              />
              <span>Yes, I consent to</span>
              <ul>
                <li>
                  Di Henneberry Property Group providing my personal information
                  to Direct Connect including name, address, email and phone
                  number.
                </li>
                <li>
                  Direct Connect contacting me by phone, SMS and/or email during
                  mymove in relation to electricity, gas and the other services
                  set out above.{" "}
                </li>
                <li>
                  Direct Connect obtaining metering information for the premises
                  I am moving to.
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <h6>Applicant 1</h6>
                <div className="col-md-6">
                  <div className="Signature">
                    <label for="your-name" class="form-label">
                      Signature<sup>*</sup>
                    </label>
                    <input
                      type="file"
                      class="form-control"
                      alt=""
                      accept="image/*"
                      onChange={(e) =>
                        handleFileInputChange(e, "applicant1_sign")
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Signature">
                    <label for="your-name" class="form-label">
                      Date<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      alt=""
                      onChange={(e) =>
                        handleInputChange(e, "", "applicant1SigningDate")
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <h6>Applicant 2 (if applicable)</h6>
                <div className="col-md-6">
                  <div className="Signature">
                    <label for="your-name" class="form-label">
                      Signature
                    </label>
                    <input
                      type="file"
                      class="form-control"
                      alt=""
                      accept="image/*"
                      onChange={(e) =>
                        handleFileInputChange(e, "applicant2_sign")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Signature">
                    <label for="your-name" class="form-label">
                      Date
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      alt=""
                      onChange={(e) =>
                        handleInputChange(e, "", "applicant2SigningDate")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6 mt-2">
                  <div className="Signature">
                    <label for="your-name" class="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={formData.applicant2SignInfo.name}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2SignInfo", "name")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="Signature">
                    <label for="your-name" class="form-label">
                      Phone
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      value={formData.applicant2SignInfo.phone}
                      onChange={(e) =>
                        handleInputChange(e, "applicant2SignInfo", "phone")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="tenancySubmitButton">
              Submit
            </button>
          </div>
        </div>
      </form>
      <Footer />
    </>
  );
}

export default TenancyApplicationForm;
