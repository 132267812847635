import React,{useState} from 'react'
import FilterHeader from '../Components/PropertyFilters/FilterHeader'
import Footer from '../Components/HomeSections/Footer'
import { CiMail } from "react-icons/ci";
import { MdLockOutline } from "react-icons/md";
import {NavLink, useNavigate} from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import "../../src/Style/Sign.css"
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineContactPhone } from "react-icons/md";
import { SuccessToaster,ErrorToaster } from "../Toster";
import {SignAPI} from "../API/AuthAPI"
const SignUp = () => {
  const navigation=useNavigate()
  const[email,setEmail]=useState('');
  const[name,setName]=useState('');
  const[phone,setPhone]=useState('');

  const[password,setPassword]=useState('');
  const[confirmPassword,setConfirmPassword]=useState('');

  const[showpassword,setShowpassword]=useState(false)
  const[showconfirmpassword,setShowconfirmpassword]=useState(false)
  let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  const handleSubmit=(e)=>{
    e.preventDefault()
   if(name===''){
    ErrorToaster(" Name field is not empty")
   } else if(emailreg.test(email)===false){
    ErrorToaster("Please enter valid email")
  } else if(phone==='' || phone.length<9 || phone.length>12){
    ErrorToaster("Phone Number 9 to 12 ")
  }
  
  else if(passReg.test(password)===false){
    ErrorToaster("Password must contain 8 characters, 1 lowercase,1 uppercase and 1 digit and 1 special character")
  }
  else if(password !==confirmPassword){
    ErrorToaster("Confirm Password is not Equel Password")
  }else{
    let fromdata={
      "name":name,
      "email":email,
      "contact_number":phone,
      "password":password,
      "confirm_password":confirmPassword
    }

    SignAPI(fromdata,navigation,setEmail,setPassword,setName,setConfirmPassword,setPhone)
  }


  

  }
  return (
    <>
   <div className='filterbanner_bg'>
   <FilterHeader/>
</div> 
<div className="container">
        <div
          className="d-flex justify-content-center align-items-center mt-5 mb-5"
          style={{ minHeight: "600px"}}
        >
          <div className="signup-box">
            <h5>Welcome to DI HENNEBERRY </h5>
            <p>We make it easy for everyone to invesst in real estate</p>
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <div className="input-box">
                <div className="icons-signup"> <MdOutlineContactPhone /></div>
                <div className="input-signup">
                <input type="text" className="form-control" name="name" value={name} onChange={(e)=>setName(e.target.value)} />
                </div>

              </div>
             
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <div className="input-box">
                <div className="icons-signup"> <CiMail /></div>
                <div className="input-signup">
                <input type="email" className="form-control" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                </div>

              </div>
             
            </div>
            <div class="mb-3">
              <label className="form-label">Phone</label>
              <div className="input-box">
                <div className="icons-signup"> <FaPhoneAlt /></div>
                <div className="input-signup">
                <input type="number" className="form-control" name="phone" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                </div>

              </div>
             
            </div>
            <div className="mb-3">
              <label className="form-label">Password</label>
              <div className="input-box">
                <div className="icons-signup"> <MdLockOutline /></div>
                <div className="input-signup">
                <input type={showpassword?"text":"password"} className="form-control" name="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                <div className="" style={{position:"absolute",top:"10px",right:"25px",cursor:"pointer"}} onClick={()=>setShowpassword(!showpassword)}>{showpassword?<FaEye />:<FaEyeSlash />}</div>
                </div>
                
              </div>
             
            </div>
            <div class="mb-3">
              <label className="form-label"> Confirm Password</label>
              <div className="input-box">
                <div className="icons-signup"> <MdLockOutline /></div>
                <div className="input-signup">
                <input type={showconfirmpassword?"text":"password"} className="form-control" name="confirmPassword" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)}/>
                <div className="" style={{position:"absolute",top:"10px",right:"25px",cursor:"pointer"}} onClick={()=>setShowconfirmpassword(!showconfirmpassword)}>{showconfirmpassword?<FaEye />:<FaEyeSlash />}</div>
                </div>
                
              </div>
             
            </div>
           
            <button type="submit" class="btn btn-primary" style={{width:"100%",marginTop:10,backgroundColor:"rgba(203, 188, 160, 0.8)",color:"black",border:"none"}}>Sign Up</button>
            <div className="d-flex justify-content-center align-items-center mt-3">
                <p className="mb-0">Don’t have an account? </p>
                <NavLink to="/login" className="text-decoration-none">Sign in</NavLink>

            </div>
          </form>
          </div>
         
        </div>
      </div>
<Footer/>
    </>
    
  )
}

export default SignUp