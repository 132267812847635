import React from "react";
import NavBar from "../Components/HomeSections/NavBar";
import Footer from "../Components/HomeSections/Footer";
import FilterHeader from "../Components/PropertyFilters/FilterHeader";
import "../Style/PropertyHeader/filterSearch.css";
import BuyProperty from "../Components/propertylisting/BuyProperty";
import NeedHelp from "./../Components/propertyDetails/NeedHelp";
import ListingSecondBanner from "../Components/propertylisting/ListingSecondBanner";

const Propertylisting = () => {
  return (
    <>
        <div className="filterbanner_bg">
          <NavBar />
          <FilterHeader />
        </div>
        <BuyProperty headingBuy="Buy Properties" />
        <ListingSecondBanner />
        <BuyProperty headingBuy="Selling Properties" />
        <BuyProperty headingBuy="House & Lands" />
        <NeedHelp />
        <Footer />
        
    </>
  );
};

export default Propertylisting;
