import React, { useState } from "react";
import Footer from "../Components/HomeSections/Footer";
import FilterHeader from "../Components/PropertyFilters/FilterHeader";
import "../../src/Style/Login.css";
import { CiMail } from "react-icons/ci";
import { MdLockOutline } from "react-icons/md";
import {NavLink, useNavigate} from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { SuccessToaster,ErrorToaster } from "../Toster";
import {loginApi} from "../API/AuthAPI"
import {setUserAuthentication} from "../Redux/actions";
import { connect } from "react-redux";
const Login = ({setUserAuthenticationRedux}) => {
  const navigation=useNavigate()
    const[email,setEmail]=useState('');
    const[password,setPassword]=useState('');
    const[showpassword,setShowpassword]=useState(false)
    let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const handleSubmit=(e)=>{
      e.preventDefault()
      if(emailreg.test(email)===false){
        ErrorToaster("Please enter valid email")
      }
      else if(passReg.test(password)===false){
        ErrorToaster("Password must contain 8 characters, 1 lowercase,1 uppercase and 1 digit and 1 special character")
      }
      else{
        // SuccessToaster("succefully")
        let fromdata={
          "email":email,
    "password":password,
    "device_token":"UIHJHHOIH799"
        }
        loginApi(fromdata,navigation,setPassword,setEmail,setUserAuthenticationRedux)
       
      }

    }
  return (
    <>
      <div className="filterbanner_bg">
        <FilterHeader />
      </div>
      <div className="container">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: 600 }}
        >
          <div className="login-box">
            <h5>Welcome to DI HENNEBERRY </h5>
            <p>We make it easy for everyone to invesst in real estate</p>
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <div className="input-box">
                <div className="icons-login"> <CiMail /></div>
                <div className="input-login">
                <input type="email" className="form-control" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                </div>

              </div>
             
            </div>
            <div className="mb-3">
              <label className="form-label">Password</label>
              <div className="input-box">
                <div className="icons-login"> <MdLockOutline /></div>
                <div className="input-login">
                <input type={showpassword?"text":"password"} className="form-control" name="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                <div className="" style={{position:"absolute",top:"10px",right:"25px",cursor:"pointer"}} onClick={()=>setShowpassword(!showpassword)}>{showpassword?<FaEye />:<FaEyeSlash />}</div>
                </div>
                
              </div>
             
            </div>
            <div className="d-flex align-items-center justify-content-end">
                <NavLink to="/forget" className="text-decoration-none">Forget Password ?</NavLink>
            </div>
            <button type="submit" className="btn btn-primary" style={{width:"100%",marginTop:10,backgroundColor:"rgba(203, 188, 160, 0.8)",color:"black",border:"none"}}>Sign In</button>
            <div className="d-flex justify-content-center align-items-center mt-3">
                <p className="mb-0">Don’t have an account? </p>
                <NavLink to="/signup" className="text-decoration-none">Sign up</NavLink>

            </div>
          </form>
          </div>
         
        </div>
      </div>
      
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    // getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
    
  
  }
    
  }

export default connect(mapStateToProps, mapDispatchToProps) (Login);
