import React, { useState } from "react";
import "../../Style/ContactForm.css";
import { ImFacebook2 } from "react-icons/im";
import { ErrorToaster } from "../../Toster";
import { ContactusApi } from "../../API/ContactUsApi";
import { NavLink } from "react-router-dom";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [subject, setSubject] = useState("");
  let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  const [selectCountryCode, setSelctCountryCode] = useState("+61");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      name === "" ||
      lastName === "" ||
      subject === "" ||
      email === "" ||
      phone === "" ||
      subject === ""
    ) {
      ErrorToaster("All fields are required");
    } else if (emailreg.test(email) === false) {
      ErrorToaster("Valid Email");
    } else if (phone.length < 9 || phone.length > 11) {
      ErrorToaster("Numner is less than 12 and greater than 11");
    } else {
      let fromdata = {
        first_name: name,
        last_name: lastName,
        email: email,
        country_code: selectCountryCode,
        contact_number: phone,
        message: subject,
      };
      ContactusApi(fromdata);
      setEmail("");
      setName("");
      setLastName("");
      setSubject("");
      setPhone("");
    }
  };

  return (
    <>
      <div className="ContactForm">
        <div className="container-xxl p-0">
          <div className="row">
            <div className="col-lg-6">
              <h3>Stay Updated. Be the First to Know.</h3>
              <p className="mb-0">
                Many clients are unaware that they are free to move to another
                agency whenever they wish and are not bound to their agents
                based on their tenant's lease term, the process is quite simple
                just ask me and I can help.
              </p>
              <p className="">
                {" "}
                Many clients are also unaware that underquoting your home can
                really impact the best outcome of the sale. I can help you with
                the best strategy to ensure you achieve the best possible
                return. Buyers are unaware that many properties sell before
                being publicly listed and with my market knowledge, agent
                relationships and understanding of the industry, I can ensure
                that you will never miss out. I look forward to chatting to you
                about your requirements and you can also find me on Linked In,
                Facebook and Instagram. In the meantime,
                <strong> watch this space</strong> for some beautifully curated
                properties including an opulent Southern Highlands' residence
                coming to the market soon. It will certainly have you feeling
                that you are to the Manor born.
              </p>
              <div className="row">
                <div className="col-md-4 col-sm-4 col-6 ">
                  <div className="home-contact-from-icons">
                    {" "}
                    <NavLink
                      to="https://www.facebook.com/profile.php?id=100009448066454&mibextid=LQQJ4d"
                      target="blank"
                    >
                      <ImFacebook2 />
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-6">
                  <div className="home-contact-from-icons">
                    {" "}
                    <NavLink
                      to="https://www.instagram.com/dihenneberry/#"
                      target="blank"
                    >
                      {" "}
                      <FaInstagramSquare />{" "}
                    </NavLink>{" "}
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-6">
                  <div className="home-contact-from-icons">
                    <NavLink
                      to="https://www.linkedin.com/in/dihenneberry/"
                      target="blank"
                    >
                      {" "}
                      <FaLinkedin />{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-4">
              <div className="detail_form">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6 col-sm-6">
                      <label for="your-name" className="form-label">
                        Your Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="your-name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label for="your-surname" className="form-label">
                        Your Surname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="your-surname"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <label for="your-email" className="form-label">
                        Your Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="your-email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className=" d-flex col-3 flex-column">
                      <label for="your-name" className="form-label">
                        Code
                      </label>

                      <input
                        className="form-control"
                        value={selectCountryCode}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px",
                        }}
                      ></input>
                    </div>
                    <div className="col-9">
                      <label for="your-name" className="form-label">
                        Phone
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-sm-12">
                      <label for="your-subject" className="form-label">
                        Message
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="your-subject"
                        name="your-subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <button type="submit">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
