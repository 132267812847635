import React,{useEffect} from "react";
import NavBar from "../Components/HomeSections/NavBar";
import Header from "../Components/HomeSections/Header";
import Footer from "../Components/HomeSections/Footer";
import "../Style/Privacy.css";
import { NavLink } from "react-router-dom";
const Privacypage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="banner_bg">
        <NavBar />
        <Header />
        <h1 className="d-flex align-items-center justify-content-center text-white">
          Privacy & Policy
        </h1>
      </div>
      <div className="container">
        <div className="privacy-contact">
          <h5>1. Introduction</h5>
          <p>
            Welcome to DI HENNEBERRY. We are committed to
            protecting your privacy and ensuring that your personal information
            is handled in a safe and responsible manner. This privacy policy
            outlines how we collect, use, and protect your information when you
            visit our website <NavLink to="www.henneberrypropertygroup.com.au" target="_blank" style={{color:"#f4d18d"}}>www.henneberrypropertygroup.com.au</NavLink>.
          </p>
          <h5>2. Information We Collect</h5>
          <h6>
            We may collect and process the following types of information:
          </h6>
          <p>
            In some cases, where it is unreasonable or impracticable to obtain
            your personal information directly from you, we will seek to obtain
            it from others, such as from your representatives (e.g. solicitor,
            accountant or financial adviser), from publicly available sources
            including social media, from Strata or Bodies Corporate, from
            On-Site Managers, and from vendors, buyers, landlords, and tenants.
          </p>
          <p>
            The personal information we collect will depend on the service we
            provide to you, and the circumstance under which we collect the
            personal information. It may include:
          </p>

          <ul>
            <li>
              <strong>Personal Identification Information:</strong>Name, email
              address, phone number, and postal address.
            </li>
            <li>
              <strong>Property Details:</strong> Information related to property
              listings, including property descriptions, images, and pricing.
            </li>
            <li>
              <strong>Usage Data:</strong>Information on how you use our
              website, including your IP address, browser type, pages viewed,
              and the dates/times of your visits.
            </li>

            <li>
              <strong>Cookies and Tracking Technologies:</strong> Information
              collected through cookies and other tracking technologies (see our
              Cookie Policy for more details).
            </li>

            <li>
              For clients accessing our Property Services (including prospective
              clients): your name, age, date of birth, contact details, family
              details, employment details, your financial information (including
              assets, liabilities, income, outgoings, spending patterns, credit
              history, credit reference reports and property valuations), your
              tax file number, your insurance details, your credit card and
              banking details, information relating to a will or estate,
              references, copies of photo identification and other information
              necessary to provide our services
            </li>

            <li>
              For our contractors, service providers and business
              associates: your name, contact details and information relevant to
              obtaining third party services or providing our services
            </li>
          </ul>

          <h5>3. How We Use Your Information</h5>
          <p>
            You have rights under privacy legislation to request copies of the
            personal information that we hold about you, and to request its
            correction. If you would like to access, or request correction of,
            the information that we hold about you, you can contact us on the
            details provided below.If we refuse access, we will provide you with
            a written notice explaining our reasons for refusing access. We may
            ask you to pay our reasonable costs of providing you with access to
            the personal information that we hold about you.
          </p>
          <p>
            We seek to ensure that the personal information that we hold is
            accurate, up-to-date and complete.  We encourage you to contact us
            on the details provided below to seek correction of the personal
            information we hold about you if you believe that it is inaccurate,
            outdated, incomplete, irrelevant or misleading.We are not obliged to
            correct any of your personal information if we do not agree that it
            requires correction and we may refuse to do so. If we refuse, we
            will provide you with a written notice explaining the basis for our
            refusal.
          </p>
          <h5>4. Questions and complaints </h5>
          <p>
            We welcome your comments regarding this Privacy Policy. If you have
            any questions about this Privacy Policy or if you have any
            questions, concerns or complaints about the way in which we have
            handled your personal information, please contact our Privacy
            Officer using the details set out below
          </p>
          <p>Att: Privacy Officer</p>
          <p>Address: Sydney NSW 2000</p>
          <p>
            Email:<NavLink to="mailto:di@dihenneberry.com.au" target="blank" style={{color:"#f4d18d"}}>di@dihenneberry.com.au </NavLink>
          </p>
          <p>
            We will investigate any complaint regarding the handling of your
            personal information and will advise you of the outcome of our
            investigations in writing. You may also lodge a complaint with the
            Office of Australian Information Commissioner by calling 1300 363
            992 or sending an email to <NavLink to="mailto:enquiries@oaic.gov.au" target="blank" style={{color:"#f4d18d"}}>enquiries@oaic.gov.au</NavLink>.
          </p>
          <p>
            More information about your rights and our obligations in relation
            to privacy, and information on making a privacy complaint are
            available from the Office of the Australian Information
            Commissioner, which can be contacted at{" "}
            <NavLink to="https://www.oaic.gov.au/" target="blank" style={{color:"#f4d18d"}}>
              www.oaic.gov.au
            </NavLink>{" "}
            or by mail to GPO Box 5218 Sydney NSW 2001 or by email to{" "}
            <NavLink style={{color:"red"}} to="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</NavLink> 
          </p>
          <h4 style={{fontWeight:"bold"}}>Changes to this Privacy Policy</h4>
          <p>
            We may amend this Privacy Policy at any time, and the current
            version will be available on our website.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Privacypage;
