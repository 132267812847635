import React, { useState } from "react";
import "../Style/Forget.css";
import Footer from "../Components/HomeSections/Footer";

import FilterHeader from "../Components/PropertyFilters/FilterHeader";
import { CiMail } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { ForgetAPI } from "../API/AuthAPI";
import { ErrorToaster } from "../Toster";
import OtpModel from "./OtpModel";

const Forget = () => {
  const [show, setShow] = useState(false);

  const [email, setEmail] = useState("");

  let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailreg.test(email) === false) {
      ErrorToaster("Please enter valid email");
    } else {
      let fromdata = {
        email: email,
      };
      setShow(true);
      ForgetAPI(fromdata, setShow, setEmail);
    }
  };
  return (
    <>
      <div className="filterbanner_bg">
        <FilterHeader />
      </div>
      <div className="container">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: 600 }}
        >
          <div className="forget-box">
            <h5>Welcome to DI HENNEBERRY </h5>
            <p>We make it easy for everyone to invesst in real estate</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <div className="input-box">
                  <div className="icons-forget">
                    {" "}
                    <CiMail />
                  </div>
                  <div className="input-forget">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  width: "100%",
                  marginTop: 10,
                  backgroundColor: "rgba(203, 188, 160, 0.8)",
                  color: "black",
                  border: "none",
                }}
              >
                OTP VERIFY
              </button>
            </form>
          </div>
          <OtpModel show={show} setShow={setShow} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Forget;
