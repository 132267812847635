import React, { useState, useEffect } from "react";
import NavBar from "../Components/HomeSections/NavBar";
import "../../src/Style/Blogpage.css";
import Footer from "../Components/HomeSections/Footer";
import FilterHeader from "../Components/PropertyFilters/FilterHeader";
import { FaChevronRight } from "react-icons/fa";
import { BlogApi } from "../API/BlogApi";
import { useNavigate } from "react-router-dom";
import Pagnitation from "./Pagnitation";


function Blogpage() {
  const [length, setLength] = useState("");
  const [data, setData] = useState([]);
  const navigation = useNavigate();
  const [currentPage, setCurrentPage] = useState("1");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Blogdata();
  }, [currentPage]);

  const Blogdata = () => {
    let fromdata = {
      page: currentPage,
    };
    BlogApi(fromdata, (callback) => {
      setLength(callback?.total_blogs);
      setData(callback?.data?.blog_list);
    });
  };

  return (
    <>
      <div className="filterbanner_bg">
        <NavBar />
        <div className="bolg-heading-banner">{/* <h2>Blog</h2> */}</div>
        <FilterHeader />
      </div>
      <div className="container-xxl p-0">
        <div className="container">
          <div className="row mt-3 mb-3">
            {data?.map((iteam, i) => {
              const { id, title, short_description, image, slug } = iteam;
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
                  <div
                    className="blogCard2"
                    onClick={() => {
                      navigation(
                        `/single-blog/${slug}_${id}`,
                        { state: id },
                        window.scrollTo(0, 0)
                      );
                    }}
                  >
                    <div className="product">
                      <img src={image} alt="alt" />
                      <div className="card_content">
                        <h6>{title?.slice(0, 25)}</h6>
                        {short_description ? (
                          <p>{short_description?.slice(0, 60)}...</p>
                        ) : (
                          ""
                        )}

                        <a href="#">
                          Read More <FaChevronRight />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Pagnitation
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalLength={length}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogpage;
