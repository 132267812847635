import axios from "axios";
import { baseUrl } from "../baseUrl";

export const singleProductdataAPI = (formdata, callback) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}property/insights`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {});
};
