import React, { useState } from "react";
import "../../Style/PropertyDetail/GorgeousApartment.css";
import landmarkImg from "../../Images/DetailsPage/landmarkGroup.png";
import { FaTrain } from "react-icons/fa";
import areaimg from "../../Images/DetailsPage/area_img.png";
import Priceimg from "../../Images/DetailsPage/price_img.png";
import floorimg from "../../Images/DetailsPage/floors_img.png";
import Propertyimg from "../../Images/DetailsPage/property_img.png";
import bedroom from "../../Images/DetailsPage/bedroom.png";
import bathroom from "../../Images/DetailsPage/bathroom.png";
import garage from "../../Images/DetailsPage/garage.png";
import livingroom from "../../Images/DetailsPage/livingroom.png";
import { ContactusApi } from "../../API/ContactUsApi";
import { ErrorToaster } from "../../Toster";
import { IoMdCall } from "react-icons/io";
import { useNavigate, NavLink } from "react-router-dom";

const GorgeousApartment = ({ data, about }) => {
  const navigation = useNavigate();
  const configurations = data?.configurations;
  const features = data?.features;
  const area = data?.area;
  const Address = data?.address;
  const bookNowLink = data && data.links && data?.links?.online_tour_2

  function rtrim(str, charToRemove = " ") {
    let regex = new RegExp(charToRemove + "+$", "g");
    return str.replace(regex, "");
  }

  const propertyDetails = [
    {
      icon: bedroom,
      label: "Bedrooms",
      value:
        configurations && configurations.number_of_bedrooms
          ? configurations.number_of_bedrooms
          : "",
    },
    {
      icon: bathroom,
      label: "Bathrooms",
      value:
        configurations && configurations.number_of_bathrooms
          ? configurations.number_of_bathrooms
          : "",
    },
    {
      icon: livingroom,
      label: "Living area",
      value:
        configurations && configurations.living_areas
          ? configurations.living_areas
          : "",
    },
    {
      icon: garage,
      label: "Garage",
      value:
        configurations && configurations.garage_spaces
          ? configurations.garage_spaces
          : "",
    },
    {
      icon: Priceimg,
      label: "Price",
      value: data?.price
        ? `$${rtrim(data.price, ".0")}`
        : data?.price_in_text
          ? data?.price_in_text
          : "",
    },
    {
      icon: floorimg,
      label: "Total Floors",
      value: configurations?.total_floors
        ? `${configurations.total_floors}`
        : null,
    },
    {
      icon: Propertyimg,
      label: "Property Age (in Years)",
      value: configurations?.property_age
        ? `${configurations.property_age}`
        : null,
    },
    {
      icon: areaimg,
      label: "Area",
      value: area?.land_size
        ? `${parseInt(area.land_size)} ${area.land_size_unit}`
        : null,
    },
  ];

  const filteredDetails = propertyDetails.filter((detail) => detail.value);

  const [selectCountryCode, setSelctCountryCode] = useState("+61");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name === "" ||
      selectCountryCode === "" ||
      email === "" ||
      phone === ""
    ) {
      ErrorToaster("All field is Required");
    } else if (emailreg.test(email) === false) {
      ErrorToaster("Valid Email");
    } else if (phone.length < 9 || phone.length > 11) {
      ErrorToaster("Number is less than 12 and greater than 11");
    } else {
      let fromdata = {
        first_name: name,
        email: email,
        country_code: selectCountryCode,
        contact_number: phone,
        message: subject,
      };
      ContactusApi(fromdata);
      setEmail("");
      setName("");
      setSubject("");
      setPhone("");
    }
  };

  return (
    <>
      <div className="GorgeousApartment">
        <div className="container p-0">
          <div className="row">
            <div className="col-lg-6">
              <div className="right_sec">
                <h4 className="text-capitalize mt-2">
                  {" "}
                  {Address?.lot_no ? `${Address?.lot_no}/` : ""}
                  {Address?.street_number} {Address?.street_name}
                  {Address?.suburb ? "," + Address?.suburb : ""}
                  {Address?.municipality ? "," + Address?.municipality : ""}
                </h4>
                <div className="prize d-flex align-items-center gap-2">
                  {data?.price ? (
                    <h4>
                      ${rtrim(data.price, ".0")}
                      {data.price_term ? `/` : ""}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "black !important",
                          opacity: 1,
                        }}
                      >
                        {" "}
                        {data.price_term ? `${data.price_term}` : ""}
                      </span>
                    </h4>
                  ) : data?.price_in_text ? (
                    <h4>{data?.price_in_text}</h4>
                  ) : (
                    <button
                      className="prize_contactus d-flex align-items-center gap-2"
                      onClick={() => navigation("/contact")}
                    >
                      <IoMdCall />
                      Contact Agent
                    </button>
                  )}
                  {
                    bookNowLink && <NavLink
                      to={bookNowLink}
                      className="text-decoration-none"
                      target="blank"
                    >
                      <button
                        className="prize_contactus d-flex align-items-center justify-content-center"
                      >
                        Book Now
                      </button>
                    </NavLink>
                  }
                </div>

                {data?.property_type && (
                  <h4 className="text-capitalize mt-2">
                    {data?.property_type}
                  </h4>
                )}

                {data?.nearByAddress?.nearby_address_1 ||
                  data?.nearByAddress?.nearby_address_2 ? (
                  <div className="places">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="landmark d-flex align-items-center gap-3">
                        <img src={landmarkImg} alt="img" />
                        <div>
                          <h6 className="m-0 fw-bold">Places Nearby</h6>
                        </div>
                      </div>
                    </div>

                    {data?.nearByAddress?.nearby_address_1 && (
                      <div className="d-flex align-items-center gap-3 train_routes mt-4">
                        <FaTrain />
                        <p className="m-0">
                          {data?.nearByAddress?.nearby_address_1}
                        </p>
                      </div>
                    )}
                    {data?.nearByAddress?.nearby_address_2 && (
                      <div className="d-flex align-items-center gap-3 train_routes">
                        <FaTrain />
                        <p className="m-0">
                          {data?.nearByAddress?.nearby_address_2}
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="leftSec mt-3" style={{ padding: "8px" }}>
                      <div className="row top d-flex justify-content-between align-items-baseline">
                        {filteredDetails.map((detail, index) => (
                          <React.Fragment key={index}>
                            <div className="col-md-6">
                              <div className="d-flex flex-column">
                                <div className="d-flex">
                                  <img src={detail.icon} alt="img" />
                                  <div className=" ms-3">
                                    <span className="opacity-75">
                                      {detail.label}
                                    </span>
                                    <p className="plot_area">{detail.value}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(index + 1) % 2 === 0 &&
                              index !== filteredDetails.length - 1 && (
                                <hr className="bottom_line" />
                              )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>

                    <div className="contact_form">
                      <h6>Send enquiry to dealer</h6>
                      <form onSubmit={handleSubmit}>
                        <lable for="name">Name*</lable>
                        <input
                          type="text"
                          className="mb-2"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <lable for="name">Email*</lable>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="mt-3 d-flex align-items-center gap-3">
                          <input
                            className="custom-select"
                            value={selectCountryCode}
                            style={{ width: "15%", height: "33px" }}
                          ></input>
                          <input
                            type="number"
                            placeholder="Phone number"
                            style={{ width: "82%" }}
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <div className="mb-3 mt-4 ">
                          <textarea
                            type="text"
                            className="form-control"
                            id="your-subject"
                            name="your-subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                        <button className="semdEmail" type="submit">
                          Send email
                        </button>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
            {about?.description && (
              <div className="col-lg-6 mt-lg-0 mt-4">
                <div className="aboutDetails">
                  <h4 className="fw-bold">{about?.headline}</h4>
                  <div
                    className="text-start"
                    dangerouslySetInnerHTML={{ __html: about?.description }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GorgeousApartment;
