import React from "react";
import "../../Style/Footer.css";
import fooerLogo from "../../Images/HomePageImages/henneberry_logo.png";
import fooerLogo1 from "../../Images/HomePageImages/henneberry_logo1.png";
import navbarLogo from "../../Images/HomePageImages/henneberry_logo1.png";

import { MdOutlineEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { IoMdCall } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { NavLink, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="footer">
        <div className="container-xxl p-0">
          <div className="row">
            <div className="col-lg-4 col-md-6 ">
              <div className="footer-about">
                <img src={navbarLogo} alt="img" onClick={() => navigate("/")} />
                <p>
                  Di Henneberry of Henneberry Property Group embodies the art of
                  refinement and offers an elite service in so many different
                  ways.
                </p>
              </div>
              {/* <div className="social_media">
              <h5>Get Connected</h5>
              <div className="d-flex align-items-center  gap-3">
                <NavLink className="icons" to="https://www.facebook.com/">
                  <FaFacebookF />
                </NavLink>
                <NavLink className="icons" to="https://www.instagram.com/accounts/login/?hl=en">
                  <FaInstagram />
                </NavLink>
                <NavLink className="icons" to="https://x.com/i/flow/login">
                  <FaXTwitter />
                </NavLink>
                <NavLink className="icons" to="https://in.linkedin.com/">
                  <FaLinkedinIn />
                </NavLink>
              </div>
            </div> */}
            </div>
            <div className="col-lg-3 col-md-6 mt-lg-0 mt-md-0 mt-4 ">
              <div className="footer-link">
                <h3>Contact Info</h3>
                <a href="#">
                  <IoLocationSharp /> Sydney, NSW Australia 2000
                </a>
                <a href="mailto:di@dihenneberry.com.au">
                  <MdOutlineEmail style={{ marginRight: "5px" }} />
                  di@dihenneberry.com.au
                </a>
                <a href="#">
                  <IoMdCall style={{ marginRight: "5px" }} />
                  +61 456761421
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 mt-lg-0 mt-4 ">
              <div className="footer-link">
                <h3>Quick Link</h3>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/blog">Blog</NavLink>
                <NavLink to="/contact">Contact Us</NavLink>
                <NavLink to="/privacy-policy">Privacy & Policy</NavLink>
                <NavLink to="/terms-conditions">Terms & Condition</NavLink>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-lg-0 mt-4 ">
              <div className="social_media">
                <h5>Get Connected</h5>
                <div className="d-flex align-items-center  gap-3">
                  <NavLink
                    className="icons"
                    to="https://www.facebook.com/profile.php?id=100009448066454&mibextid=LQQJ4d"
                    target="blank"
                  >
                    <FaFacebookF />
                  </NavLink>
                  <NavLink
                    className="icons"
                    to="https://www.instagram.com/dihenneberry/#"
                    target="blank"
                  >
                    <FaInstagram />
                  </NavLink>
                  <NavLink className="icons" to="https://x.com/i/flow/login">
                    <FaXTwitter />
                  </NavLink>
                  <NavLink
                    className="icons"
                    to="https://www.linkedin.com/in/dihenneberry/"
                    target="blank"
                  >
                    <FaLinkedinIn />
                  </NavLink>
                </div>
              </div>
              {/* <div className=" copyright">
          <div className="row">
            <div className="d-flex align-items-center justify-content-center">
              <p>Copyright © 2024.  All rights reserved</p>
            </div>
            
          </div>
        </div> */}
              {/* <div className="footer-contact">
              <h3>About Us</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div> */}
            </div>
          </div>

          <hr className="fooert_bottom_line"></hr>

          <div className=" copyright">
            <div className="row">
              <div className="d-flex align-items-center justify-content-center">
                <p>Copyright © 2024.  All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
