import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { IoIosClose } from "react-icons/io";
import OTPInput from "otp-input-react";
import Box from "@mui/material/Box";
import { ErrorToaster } from "../Toster";
import { useNavigate } from "react-router-dom";
import { OtpverifyApi } from "../API/AuthAPI";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid rgba(203, 188, 160, 0.8)",
  boxShadow: 24,
  p: 4,
};

const OtpModel = ({ show, setShow }) => {
  const [OTP, setOTP] = useState("");
  const navigation = useNavigate();

  const handleClose = () => setShow(false);
  const handlesubmit = () => {
    if (OTP === "" || OTP.length <= 3) {
      ErrorToaster("Enter otp");
    } else {
      let fromdata = {
        otp: OTP,
      };

      OtpverifyApi(fromdata, navigation, setShow);
    }
  };
  return (
    <div>
      <Modal open={show} onClose={handleClose}>
        <Box sx={style} className="Otpmodel">
          <>
            <div className="d-flex justify-content-between">
              <h5>Enter Otp</h5>
              <IoIosClose style={{ fontSize: "35px" }} onClick={handleClose} />
            </div>
            <div className="mt-3">
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
              />
            </div>
            <div className="mt-5 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-success "
                style={{
                  background: "rgba(203, 188, 160, 0.8)",
                  border: "rgba(203, 188, 160, 0.8)",
                  color: "black",
                }}
                onClick={handlesubmit}
              >
                Otp Verify
              </button>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default OtpModel;
