import React from "react";
import "../../Style/PropertyDetail/NeedHelp.css"
import { IoMdCall } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const NeedHelp = () => {
  const navigation=useNavigate();
  return (
    <>
      <div className="needHelp">
      <div className="container-xxl p-0">
        <div className="row d-flex align-items-center">
          <div className="col-md-6">
            <h3>Need help? Talk to our expert.</h3>
            <p className="m-0">Talk to our experts or browse through more properties.</p>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-md-center justify-content-start gap-3 contact_btn mt-md-0 mt-4">
              <button className="contact d-flex align-items-center gap-2" onClick={()=>navigation("/contact")}><IoMdCall />Contact Us</button>
              <button className="properties" onClick={()=>window.scrollTo(0,0)}>Buy Properties</button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default NeedHelp;
