import React from "react";
import "../../Style/Header.css";

const Header = () => {
  return (
    <>
      <div className="container-xxl p-0">
        <div className="header_banner_content">
          <h3>Refinement In Real Estate</h3>
        </div>
      </div>
    </>
  );
};

export default Header;
