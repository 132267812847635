import React from "react";
import "../../Style/HomePopularProperty.css";
import popularpropertImage from "../../Images/HomePageImages/popularProperty_image.png";
import popularpropertImage1 from "../../Images/HomePageImages/popularpropertImage1.png";
import popularpropertImage2 from "../../Images/HomePageImages/popularpropertImage3.png";
import { FaChevronRight } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareFacebook } from "react-icons/fa6";
const PopularProperty = () => {
  
  return (
    <>
      <div className="PopularProperty">
        <div className="container-xxl p-0">
          <div className="row">
            
                <div className="col-lg-4 col-md-6 col-sm-6 ">
                  <div className="card">
                    <img src={popularpropertImage} alt="img" />
                    <div className="text">
                      <h6 className="text-center m-0">
                        {/* <NavLink className="text-decoration-none text-white">WHO YOU WORK WITH MATTERS </NavLink> */}
                      </h6>
                    </div>
                    <div className="overlay">
                      <div className="text">
                      <NavLink to="https://www.facebook.com/profile.php?id=100009448066454&mibextid=LQQJ4d" target="blank">
                      <FaSquareFacebook />
                      </NavLink>
                        {/* <h5 className="m-0">hiiiii</h5>
                        <p className="m-0">
                          Browse Our Neighborhood Guides Below.
                        </p>
                        <span>
                          Learn More <FaChevronRight />
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 ">
                  <div className="card">
                    <img src={popularpropertImage1} alt="img" />
                    <div className="text">
                      <h6 className="text-center m-0">
                        {/* <NavLink className="text-decoration-none text-white">PROPERTY VALUATION </NavLink> */}
                      </h6>
                    </div>
                    <div className="overlay">
                      <div className="text">
                      <NavLink to="https://www.linkedin.com/in/dihenneberry/" target="blank">
                      <FaLinkedin />
                      </NavLink>
                        {/* <h5 className="m-0">7777777</h5>
                        <p className="m-0">
                          Browse Our Neighborhood Guides Below.
                        </p>
                        <span>
                          Learn More <FaChevronRight />
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 ">
                  <div className="card">
                    <img src={popularpropertImage2} alt="img" />
                    <div className="text">
                      <h6 className="text-center m-0">
                        {/* <NavLink className="text-decoration-none text-white ">PRESS </NavLink> */}
                      </h6>
                    </div>
                    <div className="overlay">
                      <div className="text">
                      <NavLink to="https://www.instagram.com/dihenneberry/#" target="blank">
                      <IoLogoInstagram />
                      </NavLink>
                        {/* <h5 className="m-0">888888</h5> */}
                        {/* <p className="m-0">
                          Browse Our Neighborhood Guides Below.
                        </p>
                        <span>
                          Learn More <FaChevronRight />
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularProperty;
