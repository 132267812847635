import React, { useState } from "react";
import aboutVideo_img from "../../Images/HomePageImages/aboutvideoimg5.png";
import "../../Style/HomeAboutUs.css";
import { FaRegCirclePlay } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Videomodel from "./Videomodel";

const HomeAboutUs = () => {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="HomeAboutUs">
        <div className="container-xxl p-0">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="video_card d-flex justify-content-end align-items-end"
                onClick={() => setShow(true)}
              >
                <img src={aboutVideo_img} alt="img" className="video_img" />
                {/* <img src={videobgtopimg} alt="img" /> */}
                <div className="video_icon">
                  <FaRegCirclePlay />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-4">
              <div className="about_details">
                <span className="fw-bold">About Us</span>
                <h3 className=" fw-medium my-2">MEET DI HENNEBERRY</h3>
                <p>
                  Di Henneberry of Henneberry Property Group embodies the art of
                  refinement. As a multi-award winning industry identity with an
                  enviable, 20 year career at some of Sydney’s most prestigious
                  firms, Di Henneberry brings a sparkle to every project that
                  she’s involved in. As multi-faceted as a diamond, Di
                  Henneberry offers an elite service in so many different ways:
                  This bespoke service will have her close to your side whether
                  you’re selling or buying an investment or have a property
                  under management Of course, she takes a forensic approach to
                  property inspections. It’s the small details, sometimes
                  overlooked, which may prove crucial to a future lifestyle. Her
                  knowledge of a property’s investment potential has been
                  garnered throughout her long career, not only in the eastern
                  suburbs but throughout New South Wales. Henneberry Property
                  Group has an industry contact book that’s second to none, at
                  the highest level and Di Henneberry takes a personal approach
                  to negotiating on your behalf.
                </p>
                {/* <div className="row items  d-flex">
                <div className="col-md-6">
                  <div className=" text d-flex align-items-center">
                    <span></span>
                    <p>Lorem Ipsum is simply</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text d-flex align-items-center">
                    <span></span>
                    <p>Lorem Ipsum is simply</p>
                  </div>
                </div>
                <div className="col-md-6 d-flex ">
                  <div className="text  d-flex align-items-center">
                    <span></span>
                    <p>Lorem Ipsum is simply</p>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="text  d-flex align-items-center">
                    <span></span>
                    <p>Lorem Ipsum is simply</p>
                  </div>
                </div>
              </div> */}
                <Button onClick={() => navigation("/about")}>
                  Read More <FaAngleRight />
                </Button>
              </div>
            </div>
            <Videomodel show={show} setShow={setShow} />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAboutUs;
