import React from "react";
import "../../Style/PropertyHeader/filterHeader.css";

const FilterHeader = () => {
  return (
    <>
      <div className="container-xxl p-0">
        <div className="filterHeader">

          <h3>Find Your Dream Home</h3>
        </div>
      </div>
    </>
  );
};

export default FilterHeader;
