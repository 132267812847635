import React from "react";
import "../../Style/PropertyListing/ListingSecondBanner.css";

const ListingSecondBanner = () => {
  return (
    <>
      <div className="listingBanner">
        <div className="container-xxl p-0">
          <h3>Gorgeous Apartment Bay View</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum standard dummy text ever since the 1500s, when
            an unknown
          </p>
          <button>View More Property</button>
        </div>
      </div>
    </>
  );
};

export default ListingSecondBanner;
