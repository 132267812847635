import React from "react";
import "../../Style/PropertyListing/Buyproperty.css";
import buyingcardImgOne from "../../Images/PropertyLiting/propertListingCard_img_1.png";
import { IoLocationOutline } from "react-icons/io5";

const BuyProperty = ({ headingBuy }) => {
  const butingCard = [1, 2, 3, 4];

  return (
    <>
      <div className="buyproperty">
        <div className="container-xxl p-0">
          <div className="heading d-flex justify-content-between align-items-center">
            <h4>{headingBuy}</h4>
            <a href="#"> View All</a>
          </div>
          <div className="row mt-lg mt-0">
            {butingCard.map((item, i) => {
              return (
                <div
                  className="col-lg-3 col-md-6 col-sm-6 col-12 mt-lg-0 mt-4 "
                  key={i}
                >
                  <div className="Buying_card">
                    <img src={buyingcardImgOne} alt="img" />
                    <div className="card_content">
                      <h5 className="m-0">Dubai City Sightseeing </h5>
                      <span>
                        <IoLocationOutline /> UAE \ Galleria garden
                      </span>
                      <p className="m-0">$12 - 42.50 Crore</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyProperty;
