import { USER_AUTHENTICATION } from "../type";

const initialState = {
  data: null,
};

const userAuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_AUTHENTICATION:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default userAuthenticationReducer;
