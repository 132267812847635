import React from "react";
import { IoLocationOutline } from "react-icons/io5";
import "../../Style/PropertyHeader/filterproparty.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";

function Filterproparty({ propertyData, loding, error }) {
  const navigation = useNavigate();

  function rtrim(str, charToRemove = " ") {
    let regex = new RegExp(charToRemove + "+$", "g");
    return str.replace(regex, "");
  }

  return (
    <div style={{ minHeight: !error ? "300px" : "" }} className="buyproperty">
      <div className="container-xxl p-0">
        <div className="row mt-0 gy-2">
          {loding ? (
            <Loader />
          ) : propertyData && propertyData.length > 0 ? (
            propertyData?.map((item, i) => {
              const {
                id,
                property_type,
                price,
                suburb,
                new_or_established,
                land_size,
                land_size_unit,
                number_of_bathrooms,
                number_of_bedrooms,
                outdoor_features,
                property_images,
                total_parkings,
                price_in_text,
              } = item;
              return (
                <div
                  className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3"
                  key={i}
                  onClick={() => navigation(`/property-detail_${id}`)}
                >
                  <div className="Buying_card" style={{ cursor: "pointer" }}>
                    <img src={property_images[0]} alt="img" />
                    <div className="card_content">
                      {property_type && (
                        <h5
                          className="m-0 text-capitalize"
                          style={{
                            whiteSpace: "noWrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {property_type}{" "}
                        </h5>
                      )}
                      <span>
                        <IoLocationOutline />
                        {suburb}
                      </span>
                      {price ? (
                        <p className="m-0">${rtrim(price, ".0")}</p>
                      ) : price_in_text ? (
                        <p
                          className="m-0"
                          style={{
                            whiteSpace: "noWrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {price_in_text}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Filterproparty;
