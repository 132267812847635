import React from "react";
import "../../Style/Result.css";

const Result = () => {
  return (
    <>
      <div className="result ">
        <div className="container-xxl p-0">
          <h3 className=" fw-medium">PROVEN RESULTS</h3>
          <div className="row d-flex align-items-center">
            <div className="col-12">
              <div className="content">
                <p>
                  In today's intensely competitive property market, it's challenging for industry professionals to shine. Di Henneberry has a dazzling array of awards to prove that she's done exactly that at every stage of her impressive career.
                </p>
                <p>Her many accolades include:</p>
                <p>No 1 Business Development Manager in the McGrath Network (2014- 2015)</p>
                <p>Finalist in the REB Women In Real Estate Awards in the category of Business Developer Of The Year (2019)</p>
                <p>Finalist in the REB Awards in Property Management as Business Developer Of the Year (2019)</p>
                <p>No 1. Business in Property Development at Ray White, Double Bay (2019)The Allen White Elite Property Management Business Development Achievement & Service Award 2019.</p>
                <p>In 2021, Di Henneberry also achieved the highest rental in Bondi at $6,000 per week.</p>
                <p>Continuously topping leader board at McGrath Regional for property management across the company</p>
                <p>Presenting to new Business Development Managers at McGrath Head Office to assist in building the business.</p>
                <p>Worthy entrant in the Novice Auctioneer Competition</p>
                <p>With her refined approach to marketing a property while defending her vendor's best interests, she's achieved spectacular results. A recent five week' campaign elicited $90,000 above the reserve this was all while of course quoting to buyers the current market value of the property, which was all the more impressive when taking over from a top agent who was unable to deliver. Di Henneberry aways delivers.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Result;
