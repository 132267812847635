import React, { useEffect } from "react";
import NavBar from "../Components/HomeSections/NavBar";
import Header from "../Components/HomeSections/Header";
import "../../src/Style/Header.css";
import HomePageListing from "../Components/HomeSections/HomePageListing";
import HomeAboutUs from "../Components/HomeSections/HomeAboutUs";
import Result from "../Components/HomeSections/Result";
import HomeBlog from "../Components/HomeSections/HomeBlog";
import SecondBanner from "../Components/HomeSections/SecondBanner";
import Listingproperty from "../Components/HomeSections/Listingproperty";
import PopularProperty from "../Components/HomeSections/PopularProperty";
import Footer from "../Components/HomeSections/Footer";
import ContactForm from "../Components/HomeSections/ContactForm";
import Homepropertylisting from "../Components/HomeSections/Homepropertylisting";
import videoBg from "../video/videoplayback.mp4";
import video from "../video/bg.mp4";
import Testimonial from "../Components/HomeSections/Testimonial";
import OurSupport from "../Components/HomeSections/OurSupport";

const Home = () => {
  const listingData = [
    { name: "Acquired", key: "6", slug: "acquired" },
    { name: "For Sale", key: "1", slug: "for-sale" },
    { name: "Sold", key: "5", slug: "sold" },
    // { name: "Leased", key: "4", slug: "leased" },
    // { name: "Sold", key: "5", slug: "sold" },
  ];
  const listingData2 = [
    { name: "For Lease", key: "2", slug: "for-lease" },
    { name: "Leased ", key: "4", slug: "leased" },
    { name: "Executive Leasing", key: "7", slug: "executive-leasing" },
    // { name: "Block Of Units", key: "block_of_units" },
    // { name: "Duplex", key: "duplex" },
    // { name: "Flat", key: "flat" },
    // { name: "House", key: "house" },
    // { name: "Retirement Living", key: "retirement_living" },
    // { name: "Serviced Apartment", key: "serviced_apartment" },
    // { name: "Studio", key: "studio" },
    // { name: "Terrace", key: "terrace" },
    // { name: "Townhouse", key: "townhouse" },
    // { name: "Unit", key: "unit" },
    // { name: "Villa", key: "villa" },
    // { name: "Warehouse", key: "warehouse" },
    // { name: "Other", key: "other" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <div className="banner_bg">
          <NavBar />
          <Header/>
        </div>  */}
      <div>
        <div className="video-overlay"></div>
        <video className="videoTag" autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>

        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <NavBar />
        </div>
        <div className="videoheader">
          <Header />
        </div>
      </div>
      <HomePageListing listingData={listingData} />
      <div style={{ backgroundColor: "#F6F8FF" }}>
        {/* <HomePageListing listingData={listingData2}/> */}
        <Homepropertylisting listingData={listingData2} />
      </div>
      <HomeAboutUs />
      <Result />
      <HomeBlog />
      <SecondBanner />
      {/* <Listingproperty /> */}
      <PopularProperty />
      <Testimonial />
      <ContactForm />
      <OurSupport />
      <Footer />
    </>
  );
};

export default Home;
