import React, { useState } from "react";
import "../../Style/PropertyHeader/filterTypes.css";
import { FaPlus } from "react-icons/fa6";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { IoIosArrowDown } from "react-icons/io";
import Modal from "@mui/material/Modal";
import { IoIosClose } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #354765",
  boxShadow: 24,
  p: 2,
  height: 600,
  overflowX: "scroll",
  borderRadius: 5,
};
const Typesfilter = ({
  propertyTypename,
  setSelectbadroom,
  setSelectbathroom,
  setSelectparking,
  setMinPrice,
  setMaxPrice,
  setMinLandSize,
  setMaxLandSize,
  featurekey,
  setFeaturekey,
  setPropertyType1,
  setUnit,
  handleApply,
  selectbadroom,
  selectbathroom,
  selectparking,
  propertyType1,
  selectNew,
  setSelectNew,
  open,
  setOpen,
}) => {
  const handleClose = () => setOpen(false);
  const handleFeature = (e) => {
    const { value, checked } = e.target;
    if (checked === true) {
      setFeaturekey([...featurekey, value]);
    } else {
      const index = featurekey.findIndex((item, i) => item === value);

      featurekey.splice(index, 1);
    }
  };
  const newEstablished = [
    { name: "New", value: "1" },
    { name: "Established", value: "2" },
  ];
  const Numberofparking = [
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
  ];
  const Numberofbathroom = [
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10", value: "10" },
    { name: "11", value: "11" },
    { name: "12", value: "12" },
    { name: "13", value: "13" },
    { name: "14", value: "14" },
    { name: "15", value: "15" },
    { name: "16", value: "16" },
    { name: "17", value: "17" },
    { name: "18", value: "18" },
    { name: "19", value: "19" },

    { name: "20", value: "20" },
  ];

  const Numberofbedrooms = [
    { name: "studio", value: "studio" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10", value: "10" },
    { name: "11", value: "11" },
    { name: "12", value: "12" },
    { name: "13", value: "13" },
    { name: "14", value: "14" },
    { name: "15", value: "15" },
    { name: "16", value: "16" },
    { name: "17", value: "17" },
    { name: "18", value: "18" },
    { name: "19", value: "19" },
    { name: "20", value: "20" },
    { name: "21", value: "21" },
    { name: "22", value: "22" },
    { name: "23", value: "23" },
    { name: "24", value: "24" },
    { name: "25", value: "25" },
    { name: "26", value: "26" },
    { name: "27", value: "27" },
    { name: "28", value: "28" },
    { name: "29", value: "29" },
    { name: "30", value: "30" },
  ];

  const [show, setShow] = useState("");
  const handleproperty = (key) => {
    const proid = propertyType1.find((item, i) => item === key);
    const indexNumber = propertyType1.findIndex((item, i) => item === key);

    if (proid !== key) {
      setPropertyType1([...propertyType1, key]);
      setShow(key);
    } else {
      setPropertyType1(propertyType1.filter((item) => item != key));
    }
  };
  const [value, setValue] = React.useState([0, 1000000]);
  const [size, setSize] = React.useState([0, 1000]);

  function valuetext(value) {
    return `${value}`;
  }
  function valuetext1(value) {
    return `${value}`;
  }
  const handleChangesize = (event, newValue) => {
    setSize(newValue);
    setMaxLandSize(newValue[1]);
    setMinLandSize(newValue[0]);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
  };
  const [square, setSquare] = useState();
  const handleUnitproperty = (type) => {
    setUnit(type);
    setSquare(type);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} className="fiterbox">
          <div className="d-flex justify-content-end">
            <IoIosClose
              style={{ fontSize: "35px", cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>
          <>
            <div className="MainfilterTypes">
              <div className="container-xxl p-0">
                <div className="types">
                  {propertyTypename !== "Homepropertylisting" ? (
                    <>
                      <div className="propertyTypes">
                        <h4>Property Types</h4>
                        {/* propertyTypes_Tabs-------------------- */}
                        <div className="propertyTypes_Tabs">
                          <ul>
                            {/* <li className="active">All</li> */}
                            <li
                              className={
                                propertyType1.includes("acreage")
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleproperty("acreage")}
                            >
                              <FaPlus />
                              <span className="propertysizefilter">
                                Acreage
                              </span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("alpine") ? "active" : ""
                              }
                              onClick={() => handleproperty("alpine")}
                            >
                              <FaPlus />
                              <span>Alpine</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("apartment")
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleproperty("apartment")}
                            >
                              <FaPlus />
                              <span>Apartment</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("block_of_units")
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleproperty("block_of_units")}
                            >
                              <FaPlus />
                              <span>Block of units</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("duplex") ? "active" : ""
                              }
                              onClick={() => handleproperty("duplex")}
                            >
                              <FaPlus />
                              <span>Duplex</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("flat") ? "active" : ""
                              }
                              onClick={() => handleproperty("flat")}
                            >
                              <FaPlus />
                              <span>Flat</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("house") ? "active" : ""
                              }
                              onClick={() => handleproperty("house")}
                            >
                              <FaPlus />
                              <span>House</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("retirement_living")
                                  ? "active"
                                  : ""
                              }
                              onClick={() =>
                                handleproperty("retirement_living")
                              }
                            >
                              <FaPlus />
                              <span>Retirement living</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("serviced_apartment")
                                  ? "active"
                                  : ""
                              }
                              onClick={() =>
                                handleproperty("serviced_apartment")
                              }
                            >
                              <FaPlus />
                              <span>Serviced Apartment</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("studio") ? "active" : ""
                              }
                              onClick={() => handleproperty("studio")}
                            >
                              <FaPlus />
                              <span>Studio</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("terrace")
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleproperty("terrace")}
                            >
                              <FaPlus />
                              <span>Terrace</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("townhouse")
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleproperty("townhouse")}
                            >
                              <FaPlus />
                              <span>Townhouse</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("unit") ? "active" : ""
                              }
                              onClick={() => handleproperty("unit")}
                            >
                              <FaPlus />
                              <span>Unit</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("villa") ? "active" : ""
                              }
                              onClick={() => handleproperty("villa")}
                            >
                              <FaPlus />
                              <span>Villa</span>
                            </li>
                            <li
                              className={
                                propertyType1.includes("warehouse")
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleproperty("warehouse")}
                            >
                              <FaPlus />
                              <span>warehouse</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr className="bottomHrLine" />
                    </>
                  ) : null}
                  {/* range_slider------------------------------- */}

                  <div className="range_slider ">
                    <h4>Price</h4>
                    <Box sx={{ width: "100%" }}>
                      <Slider
                        value={value}
                        onChange={handleChange}
                        min={0}
                        max={10000000}
                        getAriaValueText={valuetext}
                        valueLabelDisplay="auto"
                      />
                    </Box>

                    {/* <div className="priceDropdown">
                <button>
                  <span>Min Price</span>
                  <IoIosArrowDown className="icon" />
                </button>
                <button>
                  <span>Max Price</span>
                  <IoIosArrowDown className="icon" />
                </button>
              </div> */}
                  </div>
                  <hr className="bottomHrLine" />
                  <div className="d-flex align-items-center justify-content-between">
                    {/* badroom_Numbers------------------------------- */}
                    <div className="badroom_Numbers">
                      <h4>Number of Bedroom</h4>
                      <div className="numbers_tabs">
                        <select
                          className="filter-bathroom-select"
                          value={selectbadroom}
                          onChange={(e) => setSelectbadroom(e.target.value)}
                        >
                          <option selected style={{ position: "relative" }}>
                            {selectbadroom}
                          </option>
                          {Numberofbedrooms?.map((ele, i) => {
                            return (
                              <option key={i} value={ele.value}>
                                {ele.name}
                              </option>
                            );
                          })}
                        </select>
                        <FaAngleDown className="iconsbadroom" />
                      </div>
                    </div>
                    {/* <hr className="bottomHrLine" /> */}
                    {/* badthroom_Numbers------------------------------- */}
                    <div className="badroom_Numbers">
                      <h4>Number of Bathroom</h4>
                      <div className="numbers_tabs">
                        <select
                          className="filter-bathroom-select"
                          value={selectbathroom}
                          onChange={(e) => setSelectbathroom(e.target.value)}
                        >
                          <option selected style={{ position: "relative" }}>
                            {selectbathroom}{" "}
                          </option>
                          {Numberofbathroom?.map((ele, i) => {
                            return (
                              <option key={i} value={ele.value}>
                                {ele.name}
                              </option>
                            );
                          })}
                        </select>
                        <FaAngleDown className="iconsbathrom" />
                      </div>
                    </div>
                    {/* <hr className="bottomHrLine" /> */}

                    {/* Parking_Numbers--------------------------- */}

                    <div className="badroom_Numbers">
                      <h4>Number of Parking</h4>
                      <div className="numbers_tabs">
                        <select
                          className="filter-bathroom-select"
                          value={selectparking}
                          onChange={(e) => setSelectparking(e.target.value)}
                        >
                          <option selected style={{ position: "relative" }}>
                            {selectparking}
                          </option>
                          {Numberofparking?.map((ele, i) => {
                            return (
                              <option key={i} value={ele.value}>
                                {ele.name}
                              </option>
                            );
                          })}
                        </select>
                        <FaAngleDown className="iconsparking" />
                      </div>
                    </div>
                  </div>
                  <hr className="bottomHrLine" />
                  <div className="Property_size">
                    <h4>Property Size</h4>
                    <div className="SqFit">
                      <span>sq.ft.</span>
                      <IoIosArrowDown />
                    </div>
                    <Box sx={{ width: "100%" }}>
                      <Slider
                        value={size}
                        onChange={handleChangesize}
                        min={0}
                        max={1000}
                        getAriaValueText={valuetext1}
                        valueLabelDisplay="auto"
                      />
                    </Box>

                    <div className="d-flex justify-content-center">
                      <ul className="nav Btn_group  border-2" role="tablist">
                        <li
                          className={
                            square === "square_meters" ? "active" : "nav_btn "
                          }
                          onClick={() => handleUnitproperty("square_meters")}
                          style={{ cursor: "pointer" }}
                        >
                          Squares Metres
                        </li>
                        <li
                          className={
                            square === "square_feet" ? "active" : "nav_btn "
                          }
                          onClick={() => handleUnitproperty("square_feet")}
                          style={{ cursor: "pointer" }}
                        >
                          Squares Feet
                          {/* <span>2</span> */}
                        </li>
                        <li
                          className={
                            square === "squares" ? "active" : "nav_btn "
                          }
                          onClick={() => handleUnitproperty("squares")}
                          style={{ cursor: "pointer" }}
                        >
                          Squares
                        </li>
                        <li
                          className={
                            square === "acress" ? "active" : "nav_btn "
                          }
                          onClick={() => handleUnitproperty("acress")}
                          style={{ cursor: "pointer" }}
                        >
                          Acress
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr className="bottomHrLine" />
                  {/* Features ---------------------------------------- */}
                  <div className="Features">
                    <h4>Features</h4>
                    <div className="feature_checkboxes">
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="balcony"
                          onChange={handleFeature}
                        />
                        <span>Balcony</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="shed"
                          onChange={handleFeature}
                        />
                        <span>Shed</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="secure_parking"
                          onChange={handleFeature}
                        />
                        <span>Secure Parking</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="remote_garage"
                          onChange={handleFeature}
                        />
                        <span>Remote Gargage</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="outside_spa"
                          onChange={handleFeature}
                        />
                        <span>Outside Spa</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="outdoor_entertainment_area"
                          onChange={handleFeature}
                        />
                        <span>Outdoor Entertainment Area</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="fully_fenced"
                          onChange={handleFeature}
                        />
                        <span>Fully Fenced</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="deck"
                          onChange={handleFeature}
                        />
                        <span>Deck</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="courtyard"
                          onChange={handleFeature}
                        />
                        <span>Courtyard</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="tennis_court"
                          onChange={handleFeature}
                        />
                        <span>Tennis Court</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="swimming_pool_above_ground"
                          onChange={handleFeature}
                        />
                        <span>Swimming Pool Above Ground</span>
                      </div>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="largerCheckbox"
                          value="swimming_pool_in_ground"
                          onChange={handleFeature}
                        />
                        <span>Swimming Pool In Ground</span>
                      </div>
                    </div>
                  </div>
                  <hr className="bottomHrLine" />

                  {/* New/ Established -------------------------------- */}

                  <div className="badroom_Numbers">
                    <h4>New/ Established</h4>
                    <div className="numbers_tabs">
                      <select
                        className="filter-bathroom-select"
                        value={selectNew}
                        onChange={(e) => setSelectNew(e.target.value)}
                      >
                        <option selected style={{ position: "relative" }}>
                          {selectNew}
                        </option>
                        {newEstablished?.map((ele, i) => {
                          return (
                            <option key={i} value={ele.value}>
                              {ele.name}
                            </option>
                          );
                        })}
                      </select>
                      <FaAngleDown className="iconsNew" />
                    </div>
                  </div>
                  <hr className="bottomHrLine" />
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleApply}
                      style={{
                        backgroundColor: "#354765",
                        borderColor: "#354765",
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default Typesfilter;
