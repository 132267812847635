import "./App.css";
import { connect } from "react-redux";
import Routes from "./Routes";
import { Toaster } from "react-hot-toast";
import { setUserAuthentication } from "./Redux/actions";
import { useEffect } from "react";

function App({ setUserAuthenticationRedux, getUserAuthenticationRedux }) {
  let token = localStorage.getItem("Henneberry");
  useEffect(() => {
    if (token) {
      setUserAuthenticationRedux(token);
    } else {
      localStorage.removeItem("Henneberry");
    }
  }, [token]);

  return (
    <>
      <Routes />
      <Toaster />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
