import axios from "axios";
import { baseUrl } from "../baseUrl";
export const filterPropartydataAPI = (
  formdata,
  setLoding,
  setError,
  callback
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}sale/lease/property/list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      setLoding(false);
      callback(response?.data);
    })
    .catch((error) => {
      if (error?.response?.data?.status === "failed") {
        setError(error?.response?.data?.message);
        setLoding(false);
      }
    });
};
export const filterPropartyTypeAPI = (
  formdata,
  setLoding,
  setError,
  callback
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}sale/lease/property/list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      setLoding(false);
      callback(response?.data);
    })
    .catch((error) => {
      if (error?.response?.data?.status === "failed") {
        setLoding(false);
        setError(error?.response?.data?.message);
      }
    });
};
export const filterPropartyCatgroyAPI = (
  formdata,
  setLoding,
  setError,
  callback
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}sale/lease/property/list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
      setLoding(false);
    })
    .catch((error) => {
      if (error?.response?.data?.status === "failed") {
        setLoding(false);
        setError(error?.response?.data?.message);
      }
    });
};
