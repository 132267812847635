import React, { useEffect, useState } from "react";
import NavBar from "../Components/HomeSections/NavBar";
import Footer from "../Components/HomeSections/Footer";
import "../Style/PropertyDetail/Propertydetail.css";
import "../Style/PropertyDetail/Details_slider.css";
import FilterHeader from "../Components/PropertyFilters/FilterHeader";
import DetailsSlider from "../Components/propertyDetails/DetailsSlider";
import GorgeousApartment from "../Components/propertyDetails/GorgeousApartment";
import AboutProperty from "../Components/propertyDetails/AboutProperty";
import PropertyFAQ from "../Components/propertyDetails/PropertyFAQ";
import NeedHelp from "../Components/propertyDetails/NeedHelp";
import { singleProductdataAPI } from "../API/SingleproducatApi";
import { useParams } from "react-router-dom";

const PropertyDetail = () => {
  const [data, setData] = useState();
  const [about, setAbout] = useState();
  const [image, setImage] = useState([]);
  const { slug } = useParams();
  const Id = slug?.split("_")[1];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    singleProduct();
  }, []);

  const singleProduct = () => {
    let fromdata = {
      id: Id,
    };
    singleProductdataAPI(fromdata, (callback) => {
      setData(callback?.data?.propertyList);
      setAbout(callback?.data?.propertyList?.about);
      setImage(callback?.data?.propertyList?.images?.property_images);
    });
  };

  return (
    <>
      <div className="PropertyDetails">
        <NavBar />
        <FilterHeader />
      </div>
      <DetailsSlider image={image} />
      <GorgeousApartment data={data} about={about} />
      {/* <ServiceSlider /> */}
      <AboutProperty data={data} about={about} />
      <PropertyFAQ />
      <NeedHelp />
      <Footer />
    </>
  );
};

export default PropertyDetail;
