import React, { useRef, useState } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
// import "../../Style/PropertyDetail/Details_slider.css";
import silderImage from "../../Images/DetailsPage/details_slider_image.png";
import { MdCall } from "react-icons/md";
import { FaSearchPlus } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { RiGalleryView2 } from "react-icons/ri";

const DetailsSlider = ({ image }) => {
  const [showSlider, setShowSlider] = useState(false);
  const [sliderState, setSliderState] = useState("closed");
  const fullScreenRef = useRef();

  const images = image.map((imgUrl) => ({
    original: imgUrl,
    thumbnail: imgUrl,
  }));

  const showFullScreen = () => {
    setShowSlider(true);
    setTimeout(() => {
      fullScreenRef.current.toggleFullScreen();
    }, 0);
  };

  const renderImages = () => {
    if (image.length === 1) {
      return (
        <div className="col-md-12 position-relative" onClick={showFullScreen}>
          <img
            src={image[0]}
            alt=""
            style={{ height: "530px", width: "100%" }}
          />
          <RiGalleryView2 className="gallery-icon" onClick={showFullScreen} />
        </div>
      );
    } else if (image.length === 2) {
      return (
        <>
          <div className="col-md-8 position-relative" onClick={showFullScreen}>
            <img
              src={image[0]}
              alt=""
              style={{ height: "400px", width: "100%" }}
            />
            <RiGalleryView2 className="gallery-icon" onClick={showFullScreen} />
          </div>
          <div
            className="col-md-4 position-relative mt-4 mt-md-0"
            onClick={showFullScreen}
          >
            <img
              src={image[1]}
              alt=""
              style={{ height: "400px", width: "100%" }}
            />
            <RiGalleryView2 className="gallery-icon" onClick={showFullScreen} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="col-md-8 position-relative">
            <img
              src={image[0]}
              alt=""
              style={{ height: "480px", width: "100%" }}
            />
            <RiGalleryView2 className="gallery-icon" onClick={showFullScreen} />
          </div>
          <div className="col-md-4 d-flex justify-content-between flex-column">
            <div className="position-relative mt-4 mt-md-0">
              <img
                src={image[1]}
                alt=""
                style={{ height: "230px", width: "100%" }}
              />
              <RiGalleryView2
                className="gallery-icon"
                onClick={showFullScreen}
              />
            </div>
            <div className="position-relative mt-4 mt-md-0">
              <img
                src={image[2]}
                alt=""
                style={{ height: "230px", width: "100%" }}
              />
              <RiGalleryView2
                className="gallery-icon"
                onClick={showFullScreen}
              />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="container px-lg-0 py-5">
        <div className="row">
          <div className="col-12">
            <div className="row">{renderImages()}</div>
          </div>
        </div>
      </div>
      {showSlider && (
        <ImageGallery
          items={images}
          ref={fullScreenRef}
          onScreenChange={() => {
            if (sliderState === "closed") {
              setSliderState("open");
            } else {
              setSliderState("closed");
              setShowSlider(false);
            }
          }}
        />
      )}
    </>
  );
};

export default DetailsSlider;
