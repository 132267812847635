import React from "react";
import "../../Style/Homepropertylisting.css";
import listingimage from "../../Images/HomePageImages/homelisting_product_img_one.png";
import homelistingheadingimg from "../../Images/HomePageImages/Dimond.png";
import { FaAngleRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

function Homepropertylisting({ listingData }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return <FaChevronRight className="slider_Arrowr_right" onClick={onClick} />;
  };
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return <FaChevronLeft className="slider_Arrowr_left" onClick={onClick} />;
  };

  const navigation = useNavigate();
  const handleclick = (key, slug) => {
    navigation(`/property-filters/${slug}_${key}`, {
      state: { propertyTypekey: key, name: "Homepropertylisting" },
    });
  };
  return (
    <>
      <div className="Homepropertylisting">
        <div className="container-xxl p-0">
          <div className="homelistingheadingimg">
            <img src={homelistingheadingimg} alt="img" />
          </div>
          <div className="row">
            <Slider
              {...settings}
              nextArrow={<CustomNextArrow />}
              prevArrow={<CustomPrevArrow />}
            >
              {listingData.map((iteam, i) => {
                const { name, key, slug } = iteam;
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6 p-3"
                    key={i}
                    onClick={() => handleclick(key, slug)}
                  >
                    <div className="producta_card">
                      <img src={listingimage} alt="img" />
                      <div className="listing_text">
                        <h5>{name} </h5>
                        <span>
                          View All <FaAngleRight />{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepropertylisting;
