import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import userAuthenticationReducer from '../Redux/Reducer/userAuthenticationReducer'



export const rootReducer = combineReducers({
  userAuthentication:userAuthenticationReducer,
  });
const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
