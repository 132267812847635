import * as React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import PropertyDetail from "../Pages/PropertyDetail";
import PropertyFilters from "../Pages/PropertyFilters";
import Propertylisting from "../Pages/Propertylisting";
import Home from "../Pages/Home";
import Blogpage from "../Pages/Blogpage";
import BlogDetailssingle from "../Pages/BlogDetailssingle";
import Login from "../Auth/Login";
import SignUp from "../Auth/SignUp";
import Forget from "../Auth/Forget";
import Resetpassword from "../Auth/Resetpassword";
import Profile from "../Pages/Profile";
import Aboutus from "../Pages/Aboutus";
import Contactpage from "../Pages/Contactpage";
import Privacypage from "../Pages/Privacypage";
import Termsconditionpage from "../Pages/Termsconditionpage";
import TenancyApplicationForm from "../Pages/TenancyApplicationForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/property-filters/:propertytype",
    element: <PropertyFilters />,
  },
  {
    path: "/:slug",
    element: <PropertyDetail />,
  },
  {
    path: "/property-listing",
    element: <Propertylisting />,
  },
  {
    path: "/blog",
    element: <Blogpage />,
  },
  {
    path: "/single-blog/:slug",
    element: <BlogDetailssingle />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/forget",
    element: <Forget />,
  },
  {
    path: "/reset",
    element: <Resetpassword />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/about",
    element: <Aboutus />,
  },
  {
    path: "/contact",
    element: <Contactpage />,
  },
  {
    path: "/privacy-policy",
    element: <Privacypage />,
  },
  {
    path: "/terms-conditions",
    element: <Termsconditionpage />,
  },
  {
    path: "/tenancy-application-form",
    element: <TenancyApplicationForm />,
  },
]);

function Routes() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default Routes;
