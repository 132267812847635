import React, { useEffect, useState } from "react";
import "../../Style/HomeBlog.css";
import Button from "react-bootstrap/Button";
import { FaAngleRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { BlogApi } from "../../API/BlogApi";
import { useNavigate } from "react-router-dom";

const HomeBlog = () => {
  const [length, setLength] = useState();
  const [data, setData] = useState([]);

  const navigation = useNavigate();

  useEffect(() => {
    Blogdata();
  }, []);

  const Blogdata = () => {
    let fromdata = {
      page: 1,
    };
    BlogApi(fromdata, (callback) => {
      setLength(callback?.data.total_blogs);
      setData(callback?.data?.blog_list);
    });
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return <FaChevronRight className="slider_Arrowr_right" onClick={onClick} />;
  };
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return <FaChevronLeft className="slider_Arrowr_left" onClick={onClick} />;
  };

  return (
    <>
      <div className="Blog_sec">
        <div className="container-xxl p-0">
          <div className="heading d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h3>READ OUR BLOGS</h3>
              <p>
                Your Go-to Source for the Latest Trends in Real Estate, Sydney,
                and So Much More.
              </p>
            </div>
            <Button
              onClick={() => {
                navigation("/blog");
                window.scrollTo(0, 0);
              }}
              className="homeblogbutton"
            >
              View All <FaAngleRight />
            </Button>
          </div>
          <div className="Blog_slider gap-3">
            {data?.length > 4 ? (
              <Slider
                {...settings}
                nextArrow={<CustomNextArrow />}
                prevArrow={<CustomPrevArrow />}
              >
                {data.map((item, i) => {
                  const { id, title, short_description, image, slug } = item;
                  return (
                    <div
                      className="blogCard"
                      key={i}
                      onClick={() =>
                        navigation(`/single-blog/${slug}_${id}`, { state: id })
                      }
                    >
                      <div className="product">
                        <img src={image} alt="alt" />
                        <div className="card_content">
                          <h6>{title.slice(0, 25)}</h6>
                          {short_description ? (
                            <p>{short_description?.slice(0, 30)}...</p>
                          ) : (
                            ""
                          )}

                          <a href="#">
                            Read More <FaChevronRight />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="row">
                {data.map((item, i) => {
                  const { id, title, short_description, image, slug } = item;
                  return (
                    <div
                      className="blogCard col-xl-3 col-lg-4 col-sm-6"
                      key={i}
                      onClick={() =>
                        navigation(`/single-blog/${slug}_${id}`, { state: id })
                      }
                    >
                      <div className="product">
                        <img src={image} alt="alt" />
                        <div className="card_content">
                          <h6>{title.slice(0, 25)}</h6>
                          {short_description ? (
                            <p>{short_description?.slice(0, 30)}...</p>
                          ) : (
                            ""
                          )}

                          <a href="#">
                            Read More <FaChevronRight />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBlog;
