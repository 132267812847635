import axios from "axios";
import { baseUrl } from "../baseUrl";

export const BlogApi = (formdata, callback) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}get/blog/list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {});
};
export const SingleblogApi = (formdata, setLoding, callback) => {
  let data = JSON.stringify(formdata);
  setLoding(false);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}blog/details`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {});
};
