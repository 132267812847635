import axios from "axios";
import { baseUrl } from "../baseUrl";
import { ErrorToaster, SuccessToaster } from "../Toster";

export const userinfoAPI = (token, setLoding, navigation, callback) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch/profile`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .request(config)
    .then((response) => {
      setLoding(false);
      callback(response?.data);
    })
    .catch((error) => {
      setLoding(false);

      localStorage.removeItem("Henneberry");

      navigation("/");
    });
};

export const UpDateInfo = (token, fromdata) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update/profile`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const ProfileChange = (token, profile, callback) => {
  let data = JSON.stringify(profile);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update/profile/picture`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data?.filePath);
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};
