import React,{useEffect} from "react";
import NavBar from "../Components/HomeSections/NavBar";
import Header from "../Components/HomeSections/Header";
import Footer from "../Components/HomeSections/Footer";
import "../Style/Terms.css";
import { NavLink } from "react-router-dom";
const Termsconditionpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="banner_bg">
        <NavBar />
        <Header />
        <h1 className="d-flex align-items-center justify-content-center text-white">
          Terms & Conditions
        </h1>
      </div>
      <div className="container">
        <div className="terms-contact">
          <h5>1.Introduction</h5>
          <p>
            Welcome to DI HENNEBERRY . These terms and
            conditions outline the rules and regulations for the use of{" "}
          DI HENNEBERRY's website, located at [Your
            Website URL]. By accessing this website, we assume you accept these
            terms and conditions. Do not continue to use{" "}
          DI HENNEBERRY if you do not agree to all of the
            terms and conditions stated on this page.
          </p>
          <h5>2. Intellectual Property Rights</h5>
          <p>
            Unless otherwise stated, DI HENNEBERRY  and/or
            its licensors own the intellectual property rights for all material
            on DI HENNEBERRY . All intellectual property
            rights are reserved. You may access this from{" "}
          DI HENNEBERRY for your own personal use
            subjected to restrictions set in these terms and conditions.
          </p>
          <h6>You must not:</h6>
          <ul>
            <li>
              Republish material from DI HENNEBERRY 
            </li>
            <li>
              Sell, rent, or sub-license material from{" "}
            DI HENNEBERRY
            </li>
            <li>
              Reproduce, duplicate, or copy material from{" "}
            DI HENNEBERRY
            </li>
            <li>
              Redistribute content from DI HENNEBERRY
            </li>
          </ul>
          <h5>3. User Responsibilities</h5>
          <ul>
<li>Users must provide accurate, current, and complete information during the registration process and update such information to keep it accurate, current, and complete.</li>
          <li>Users are responsible for maintaining the confidentiality of their account and password and for restricting access to their computer.</li>
          <li>Users agree to accept responsibility for all activities that occur under their account or password.</li>
          </ul>
          <h5>4. Prohibited Activities</h5>
          <h6>Users may not use the website for any of the following activities:</h6>
          <ul>
            <li>Engaging in any unlawful activity.</li>
            <li>Posting any misleading, false, or inaccurate information.</li>
            <li>Transmitting or uploading viruses or any other harmful software.</li>
<li>Using the website in any manner that could damage, disable, overburden, or impair the website.</li>
          </ul>
          <h5>5. Listings and Transactions</h5>
          <ul>
            <li>All property listings are provided "as is" without any guarantees or warranty.</li>
            <li>DI HENNEBERRY  does not own any of the properties listed on the website and is not responsible for any transaction between the buyer and the seller.</li>

            <li>Users must ensure they comply with all legal obligations in relation to property transactions.</li>

          </ul>
          <h5>6. Limitation of Liability </h5>
          <p>
            In no event shall DI HENNEBERRY , nor any of its
            officers, directors, and employees, be held liable for anything
            arising out of or in any way connected with your use of this website
            whether such liability is under contract.DI HENNEBERRY ,
            including its officers, directors, and employees shall not be held
            liable for any indirect, consequential, or special liability arising
            out of or in any way related to your use of this website.
          </p>
          <h5>7. Indemnification</h5>
          <p>
            You hereby indemnify to the fullest extent{" "}
          DI HENNEBERRY from and against any and/or all
            liabilities, costs, demands, causes of action, damages, and expenses
            arising in any way related to your breach of any of the provisions
            of these terms.
          </p>
          <h5>8. Privacy Policy</h5>
          <p>
            Your privacy is very important to us. Please refer to our Privacy
            Policy [insert link] for details on how we collect, use, and protect
            your information.
          </p>
          <h5>9. Changes to Terms</h5>
          <p>
          DI HENNEBERRY reserves the right to revise
            these terms and conditions at any time as it sees fit, and by using
            this website you are expected to review these terms on a regular
            basis.
          </p>
          <h5>10. Governing Law</h5>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of New South Wales, and you irrevocably
            submit to the exclusive jurisdiction of the courts in that State or
            location.
          </p>

          <h5>11. Contact Information</h5>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at{" "}
          </p>
          <NavLink to="mailto:di@dihenneberry.com.au" target="blank" style={{color:"#f4d18d"}}>di@dihenneberry.com.au</NavLink>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Termsconditionpage;
